import { Component, AfterContentInit } from '@angular/core';
import { ActionsService } from '../services/actions.service';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-airtime-data',
  templateUrl: './airtime-data.component.html',
  styleUrls: ['./airtime-data.component.scss']
})
export class AirtimeDataComponent {
  public data: any = {};
  constructor(
    public action: ActionsService,
    public currence: CurrencyPipe
  ) {

  }

  async ngAfterContentInit(): Promise<void> {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    if (this.action.getItem() && this.action.getItem().type) {
      this.data['type'] = this.action.getItem()?.type;
      try {
        this.data['loading'] = true;
        const get = await this.action.Request(`billpayment/getBillers?type=${this.data.type}`, {}, 'GET');
        this.data.loading = false;
        if (!get.data.success) {
          this.action.toast('error', get.data.message);
          return;
        }
        //  console.log(get.data.data)
        this.data['providers'] = get.data.data;
      } catch (error: any) {
        this.data.loading = false;
        this.action.toast('error', error, 'An error occured')
      }
    }
    else {
      this.action.history()
    }
  }

  public getPlans = async () => {
    if (!this.data.selectedProvider) return;
    this.data['busy'] = true;
    try {
      //console.log(this.data.selectedProvider)
      const get = await this.action.Request(`billpayment/data/plans`, {
        billerId: this.data.selectedProvider.billerId
      }, 'POST');
      this.data.busy = false;
      if (!get.data.success) {
        this.action.toast('error', get.data.message);
        return;
      }
      //  console.log(get.data.data)
      this.data['plans'] = get.data.data;
    } catch (error: any) {
      console.log(error)
      this.data.busy = false;
      this.action.toast('error', error, 'An error occured')
    }
  }

  public proceed = async (): Promise<void> => {
    //console.log(this.data)
    const header = this.data.type == 'airtime' ? 'Confirm airtime purchase' : 'Confirm data purchase';
    const amount = this.data.type == 'airtime' ? `${this.currence.transform(parseInt(this.data.amount), 'NGN')}` : `${this.data.selectedPlan.description}`;
    const body = this.data.type == 'airtime' ? {
      Provider: this.data.selectedProvider.code,
      'Recharge no': this.data.phone
    } :
      {
        Provider: this.data.selectedProvider.code,
        'Recharge no': this.data.phone,
        'Amount': `${this.currence.transform(parseInt(this.data.selectedPlan.amount), 'NGN')}`
      }
    const modal = await this.action.paymentConfirmationModal(
      {
        width: this.action.data.currentSize <= 780 ? "80%" : '30%',
        height: 'auto',
        header: header
      },
      `${amount}`,
      body
      ,
      true,
      {
        'from wallet balance': ''
      },
      true,
      'Confirm to pay'
    );
    //console.log(modal)
    this.data['side'] = true;
  }

  public doWidth = (): { width: string } => {
    const current = this.action.data.currentSize;
    //console.log(current)
    return {
      width: current <= 768 ? "70%" : "25%"
    };
  }

  validate = () => {
    const letter = /^-?\d+\.?\d*$/.test(this.data.phone);
    !letter ? this.data.phone = this.data.phone.slice(0, this.data.phone.length - 1, 10) : ''
  }


  getInput = (ev: { complete: boolean, type: string, value: string }) => {
    if (ev.complete) {
      this.data['pin'] = ev.value;
      this.data['disable'] = false;
      // console.log(ev.value)
    }
    else {
      this.data['disable'] = true;
    }
  }

  proceedPay = async () => {
    this.data.side = false;
    const payload = {
      "amount": this.data.type == 'airtime' ? this.data.amount : this.data.selectedPlan.amount,
      "billerName": this.data.selectedProvider.name,
      "billerCode": this.data.type == 'airtime' ? this.data.selectedProvider.code : this.data.selectedPlan.code,
      "billerId": this.data.selectedProvider.billerId,
      "mobile": this.data.phone,
      "type": this.data.type,
      pin: this.data.pin
    }
    //console.log(payload)
    try {
      this.action.data['blocked'] = true;
      const load = await this.action.Request('billpayment/buyAirtime', payload, 'POST');
      this.action.data.blocked = false;
      if (!load.data.success) {
        this.action.transactionResponse('error', { title: 'Cable tv purchase failed', message: load.data.message }, true, 'proceed');
        return;
      }
      //console.log(load.data.data)
      //transaction successful, load modal
      this.data.type == 'airtime' ? this.action.transactionResponse('success', { title: 'Airtime purchase successful', message: `You have successfully purchased airtime of ${payload?.amount}` }, true, 'Done') : this.action.transactionResponse('success', { title: 'Data purchase successful', message: `You have successfully purchased N${payload?.amount} of data` }, true, 'Done')
      this.action.history()
    } catch (error: any) {
      // console.log(error)
      this.action['data']['blocked'] = false;
      this.action.transactionResponse('error', { title: 'Purchase failed', message: error.response ? error.response.data.message : error }, true, 'proceed');
    }
  }

}
