<!-- <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar> -->
<div *ngIf="action.checkBrowser()">
  <p-toast></p-toast>
  <p-confirmDialog #cd>
    <ng-template pTemplate="header" *ngIf="!cd.header">
      <div class="center-flex">

      </div>

      <div>

        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 104 103" fill="none">
            <path
              d="M64.8757 97.638H39.1257C15.8219 97.638 5.86523 87.6814 5.86523 64.3776V38.6276C5.86523 15.3239 15.8219 5.36719 39.1257 5.36719H64.8757C88.1794 5.36719 98.1361 15.3239 98.1361 38.6276V64.3776C98.1361 87.6814 88.1794 97.638 64.8757 97.638ZM39.1257 11.8047C19.3411 11.8047 12.3027 18.843 12.3027 38.6276V64.3776C12.3027 84.1622 19.3411 91.2005 39.1257 91.2005H64.8757C84.6602 91.2005 91.6986 84.1622 91.6986 64.3776V38.6276C91.6986 18.843 84.6602 11.8047 64.8757 11.8047H39.1257Z"
              fill="#56006B" />
            <path
              d="M38.3094 78.3246C38.009 78.3246 37.6656 78.3246 37.3652 78.2387L32.2581 77.5521C29.1681 77.1229 26.4644 74.4621 25.9923 71.2863L25.2627 66.0933C24.9623 64.0333 25.8206 61.4154 27.3227 59.8704L37.1077 50.0854C35.6485 43.1329 37.7515 35.9229 42.8585 30.8587C51.0556 22.6617 64.4456 22.6617 72.6427 30.8587C76.634 34.85 78.8227 40.1287 78.8227 45.7508C78.8227 51.3729 76.634 56.6946 72.6427 60.6429C67.5356 65.7071 60.3256 67.81 53.416 66.3508L43.5881 76.1358C42.3435 77.4662 40.2406 78.3246 38.3094 78.3246ZM57.7506 31.1162C54.0169 31.1162 50.2402 32.5325 47.4077 35.4079C43.5452 39.2704 42.1719 44.8925 43.7598 50.1283C44.1031 51.2871 43.8027 52.4888 42.9444 53.3471L31.8719 64.4196C31.786 64.5483 31.6144 65.0204 31.6144 65.1921L32.344 70.3421C32.3869 70.5996 32.859 71.0717 33.1165 71.1146L38.2665 71.8442C38.481 71.8013 38.9102 71.7154 39.039 71.5867L50.1544 60.4713C51.0127 59.6129 52.2573 59.3554 53.3731 59.6987C58.566 61.3296 64.1881 59.9562 68.0506 56.0937C70.7973 53.3471 72.3423 49.6562 72.3423 45.7508C72.3423 41.8454 70.8402 38.1546 68.0506 35.4079C65.261 32.5754 61.4844 31.1162 57.7506 31.1162Z"
              fill="#56006B" />
            <path
              d="M45.3442 73.0864C44.5287 73.0864 43.7133 72.7859 43.0696 72.1422L39.3787 68.4514C38.1342 67.2068 38.1342 65.1468 39.3787 63.9022C40.6233 62.6576 42.6833 62.6576 43.9279 63.9022L47.6187 67.593C48.8633 68.8376 48.8633 70.8976 47.6187 72.1422C47.0179 72.7859 46.2025 73.0864 45.3442 73.0864Z"
              fill="#56006B" />
            <path
              d="M57.9186 50.6336C57.3177 50.6336 56.7598 50.5478 56.2448 50.3332C55.7298 50.1186 55.2577 49.7753 54.8715 49.3891C54.0561 48.5736 53.584 47.5007 53.584 46.342C53.584 45.2261 54.0561 44.1103 54.8715 43.3378C55.8586 42.3078 57.3607 41.8357 58.734 42.1361C58.9915 42.222 59.2919 42.2649 59.5494 42.3936C59.8069 42.5224 60.0644 42.6511 60.279 42.7799C60.4936 42.9516 60.7511 43.1232 60.9227 43.3378C61.7382 44.1103 62.2102 45.2261 62.2102 46.342C62.2102 46.9428 62.0815 47.4578 61.8669 48.0157C61.6523 48.5307 61.3519 49.0028 60.9227 49.3891C60.1073 50.2045 59.0344 50.6336 57.9186 50.6336Z"
              fill="#56006B" />
          </svg>
        </div>
      </div>
      <div>

      </div>
    </ng-template>

    <ng-template pTemplate="message" *ngIf="!cd.header">
      <div>
        <h1 class="font-bold font-mid"><strong>Proceed to wallet</strong></h1>
        <p class="font-light font-small">Are you sure you want to submit this information?</p>
      </div>
    </ng-template>
  </p-confirmDialog>
  <p-blockUI [blocked]="action.data.blocked">
    <p-progressSpinner animationDuration=".5s"></p-progressSpinner>
  </p-blockUI>
</div>
<div class="content" *ngIf="action.data.init">
  <app-sidemenu></app-sidemenu>
  <app-header></app-header>
  <!-- Main content goes here -->
  <router-outlet></router-outlet>
</div>