import { Component } from '@angular/core';
import { ActionsService } from '../services/actions.service';
import { AxiosResponse } from 'axios';

@Component({
  selector: 'app-fund',
  templateUrl: './fund.component.html',
  styleUrls: ['./fund.component.scss']
})
export default class FundComponent {
  data: any = {};
  constructor(
    public action: ActionsService
  ) {
    this.data['side'] = false;
    //console.log(this.action.data.currentSize)
    this.data['ussd'] = [
      {
        name: "GtBank",
        img: '../../assets/skins/gtb.svg',
        code: `*737*${this.data.amount ? this.data.amount : 'amount'}*416#`,
        bg: 'rgba(244, 97, 29, 0.37)'
      },
      {
        name: "Zenith bank",
        img: '../../assets/skins/zen.svg',
        code: `*966*${this.data.amount ? this.data.amount : 'amount'}*AccountNumber#`,
        bg: 'rgba(163, 178, 197, 0.37)'
      },
      {
        name: "Access Bank",
        img: '../../assets/skins/access.svg',
        code: `*901*${this.data.amount ? this.data.amount : 'amount'}*AccountNumber#`,
        bg: 'rgba(245, 129, 31, 0.37)'
      },
      {
        name: "Polaris Bank",
        img: '../../assets/skins/pol.svg',
        code: `*833*${this.data.amount ? this.data.amount : 'amount'}*AccountNumber#`,
        bg: 'rgba(120, 20, 150, 0.37)'
      },
      {
        name: "Wema Bank",
        img: '../../assets/skins/wem.svg',
        code: `*945*${this.data.amount ? this.data.amount : 'amount'}*AccountNumber#`,
        bg: '#DDE3EA'
      }
    ]
  }

  getWallet = async () => {
    try {
      this.data.loading = true;
      this.data.side = true;
      this.data['current'] = 'wallet'
      const wallet: AxiosResponse = await this.action.Request('transaction/getWallet', {}, 'GET');
      this.data.loading = false
      if (wallet.data.success) {
        this.data['wallet'] = wallet.data.data;
      }
      else {
        this.action.toast('warning', wallet.data.message)
      }
    } catch (error: any) {
      this.data.loading = false;
      this.action.toast('error', error)
    }

  }

  public loadUssd = () => {
    this.data['ussd'] = [
      {
        name: "GtBank",
        img: '../../assets/skins/gtbank.svg',
        code: `*737*${this.data.amount ? this.data.amount : 'amount'}*416#`,
        bg: 'rgba(244, 97, 29, 0.37)'
      },
      {
        name: "Zenith bank",
        img: '../../assets/skins/zenith.svg',
        code: `*966*${this.data.amount ? this.data.amount : 'amount'}*AccountNumber#`,
        bg: 'rgba(163, 178, 197, 0.37)'
      },
      {
        name: "Access Bank",
        img: '../../assets/skins/access.png',
        code: `*901*${this.data.amount ? this.data.amount : 'amount'}*AccountNumber#`,
        bg: 'rgba(245, 129, 31, 0.37)'
      },
      {
        name: "Polaris Bank",
        img: '../../assets/skins/polaris.png',
        code: `*833*${this.data.amount ? this.data.amount : 'amount'}*AccountNumber#`,
        bg: 'rgba(120, 20, 150, 0.37)'
      },
      {
        name: "Wema Bank",
        img: '../../assets/skins/wema.png',
        code: `*945*${this.data.amount ? this.data.amount : 'amount'}*AccountNumber#`,
        bg: '#DDE3EA'
      }
    ]
  }

  public doWidth = (): { width: string } => {
    const current = this.action.data.currentSize;
    return {
      width: current <= 768 ? "70%" : "25%"
    };
  }

}
