import { Component } from '@angular/core';
//import { EventEmitter } from 'stream';
import { ActionsService } from 'src/app/services/actions.service';
@Component({
  selector: 'app-setpins',
  templateUrl: './setpins.component.html',
  styleUrls: ['./setpins.component.scss']
})
export class SetpinsComponent {
  data: any = {}
  constructor(
    public action: ActionsService
  ) {
    this.data['disable'] = true;
  }

  getInput = (ev: { complete: boolean, type: string, value: string }) => {
    if (ev.complete) {
      this.data[ev.type] = ev.value;
      //console.log(this.data)
      if (this.data['pin'] && this.data['pin-confirm'] && this.data.pin == this.data['pin-confirm']) {
        this.data['disable'] = false;

      }
      else if (this.data['pin'] && this.data['pin-confirm'] && this.data.pin != this.data['pin-confirm']) {
        this.action.toast('warning', 'Pin must match')
        this.data['disable'] = true
      }
      else {
        this.data['disable'] = true
      }
    }
    else {
      this.data['type'] = null;
      this.data['disable'] = true;
    }

  }

  getInput2 = (ev: { complete: boolean, type: string, value: string }) => {
    if (ev.complete) {
      this.data[ev.type] = ev.value;
      //console.log(this.data)
      if (this.data['pin']) {
        this.data['disable'] = false;

      }
      else {
        this.data['disable'] = true
      }
    }
    else {
      this.data['type'] = null;
      this.data['disable'] = true;
    }

  }



  proceed = async () => {
    // console.log('herjes')
    const confirm = await this.action.alertConfirm('Are sure you want to submit this information?');
    if (!confirm) return;
    //console.log(this.action.getItem().id)
    const payload = {
      otp: this.data['otp'],
      user_id: this.action.getItem().id,
      pin: this.data['pin'],
      pin_confirmation: this.data['pin-confirm']
    };
    this.data['loading'] = true;
    try {
      const load = await this.action.Request('auth/setWalletPin', payload, 'POST');
      this.data.loading = false;
      load.data.data['token'] = load.data.token;
      //console.log(load.data.success)
      if (load.data.success) {
        this.action.modalClose({
          success: true, ...load.data.data
        })
      }
      else {
        this.action.toast('error', load.data.message)
      }
    } catch (error: any) {
      this.action.toast('error', error.response.data ? error.response.data.message : error)
      this.data.loading = false
    }
  }

  proceedTransactionPin = async () => {
    // console.log('herjes')
    const confirm = await this.action.alertConfirm('Are sure you want to submit this information?');
    if (!confirm) return;
    //console.log(this.action.getItem().id)
    const payload = {
      user_id: this.action.getItem().id,
      pin: this.data['pin'],
      pin_confirmation: this.data['pin-confirm']
    };
    this.data['loading'] = true;
    try {
      const load = await this.action.Request('auth/setTransactionPin', payload, 'POST');
      this.data.loading = false;
      load.data.data['token'] = load.data.token;
      //console.log(load.data.success)
      if (load.data.success) {
        this.action.modalClose({
          success: true, ...load.data.data
        })
      }
      else {
        this.action.toast('error', load.data.message)
      }
    } catch (error: any) {
      this.action.toast('error', error.response.data ? error.response.data.message : error)
      this.data.loading = false
    }
  }


  proceedPin = async () => {
    // console.log('herjes')
    const confirm = await this.action.alertConfirm('Are sure you want to submit this information?', true, {
      acceptLabel: 'Yes proceed',
      rejectLabel: 'No',
      acceptButtonStyleClass: 'color',
      rejectButtonStyleClass: 'color',
      accept: async () => {
        const payload = {
          user_id: this.action.getItem().id,
          pin: this.data['pin']
        };
        this.data['loading'] = true;
        try {
          const load = await this.action.Request('auth/loginPin', payload, 'POST');
          this.data.loading = false;
          load.data.data['token'] = load.data.token;
          //console.log(load.data.success)
          if (load.data.success) {
            this.action.modalClose({
              success: true, ...load.data.data
            })
          }
          else {
            this.action.toast('error', load.data.message)
          }
        } catch (error: any) {
          this.action.toast('error', error.response.data ? error.response.data.message : error)
          this.data.loading = false
        }
      }
    });
    if (!confirm) return;
    //console.log(this.action.getItem().id)
  }
}
