import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ButtonModule } from 'primeng/button';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import {ConfirmationService,MessageService} from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { SidemenuComponent } from './globals/sidemenu/sidemenu.component';
import { MenuModule } from 'primeng/menu';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { HeaderComponent } from './globals/header/header.component';
import { MenubarModule } from 'primeng/menubar';
import FundComponent from './fund/fund.component';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
//import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler';
import {InputTextModule} from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { SetpinsComponent } from './modals/setpins/setpins.component';
import { PinInputComponent } from './globals/pin-input/pin-input.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TransferComponent } from './transfer/transfer.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PayConfirmComponent } from './pay-confirm/pay-confirm.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ResponseComponent } from './globals/response/response.component';
import { ServerModule } from '@angular/platform-server';
import { BillsPayComponent } from './bills-pay/bills-pay.component';
import { BillsMenuComponent } from './bills-menu/bills-menu.component';
import { ConfirmationComponent } from './modals/confirmation/confirmation.component';
import { CurrencyPipe } from '@angular/common';
import { AirtimeDataComponent } from './airtime-data/airtime-data.component';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SidemenuComponent,
    HeaderComponent,
    FundComponent,
    SetpinsComponent,
    PinInputComponent,
    TransferComponent,
    PayConfirmComponent,
    ResponseComponent,
    BillsPayComponent,
    BillsMenuComponent,
    ConfirmationComponent,
    AirtimeDataComponent,
    TransactionDetailsComponent
  ],
  imports: [
    ServerModule,
    InputNumberModule,
    InputSwitchModule,
    DropdownModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    FormsModule,
    InputTextModule,
    ProgressBarModule,
    ProgressSpinnerModule,  
    BlockUIModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ButtonModule,
    SidebarModule,
    MenuModule,
    DividerModule,
    TableModule,
    MenuModule,
    MenubarModule,
    SkeletonModule,
    ToastModule
  ],
  providers: [
    DialogService,
    ConfirmationService,
    MessageService,
    DynamicDialogConfig,
    CurrencyPipe
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
