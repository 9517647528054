import { Component,AfterContentInit, AfterContentChecked, OnInit } from '@angular/core';
import { ActionsService } from '../services/actions.service';
import { AxiosResponse } from 'axios';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public data:any={}
constructor(
  public action:ActionsService
){
//this.action.createLoader('hi')
this.data['hidden']=false;
}
ngOnInit(): void {

}

async ngAfterContentInit(){
 // console.log('ibit')
  //Called after every check of the component's or directive's content.
  //Add 'implements AfterContentChecked' to the class.
 await this.refresh();
}

refresh=async(): Promise<void>=>{
  try {
    //load user info
    this.action.data['loading']=true;
    const user=this.action.secureGet('user');
    const walletInfo:Array<Promise<AxiosResponse>>=[this.action.Request('transaction/getWallet',{},'GET'),this.action.Request('transaction',{},'GET')];
    const getter=await Promise.all(walletInfo);
   if(!getter[0].data.success || !getter[1].data.success){
    this.action.toast('error','Sorry an error has occured fetching some information');
    return;
   }
    this.action.data['loading']=false;
    this.data['walletInfo']=getter[0].data.data;
    this.data['transactions']=getter[1].data.data;
    //console.log(this.data.transactions)
  } catch (error:any) {
   console.log(error);
   this.action.toast('error',error)
  }
}
}
