import { Component, AfterContentInit} from '@angular/core';
import { ActionsService } from '../services/actions.service';
@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent {
public data:any={};
constructor(
  public action:ActionsService
){

}

async ngAfterContentInit(): Promise<void> {
  if(!this.action.getItem())return this.action.history();
  //Called after ngOnInit when the component's or directive's content has been initialized.
  //Add 'implements AfterContentInit' to the class.
  try {
    this.data['loading']=true;
    const info=await this.action.Request(`transaction?reference=${this.action.getItem()}`,{},'GET');
    this.data.loading=false;
    if(!info.data.success){
      this.action.toast('error',info.data.message);
      return;
    }
    //console.log(info.data.data)
    this.data['details']=info.data.data;
  } catch (error:any) {
    this.data.loading=false;
    this.action.toast('error',error)
  }
}

}
