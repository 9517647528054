import { Component } from '@angular/core';
import { ActionsService } from '../services/actions.service';
@Component({
  selector: 'app-pay-confirm',
  templateUrl: './pay-confirm.component.html',
  styleUrls: ['./pay-confirm.component.scss']
})
export class PayConfirmComponent {
  public data:any={}
constructor(
  public action:ActionsService
){
if(!this.action.getItem()){
  this.action.history()
}
else{
  //console.log(this.action.getItem())
  this.data=this.action.getItem()
}
this.data['disable']=true;
}

public doWidth=():{width:string}=>{
  const current=this.action.data.currentSize;
  return {
  width:current<=768?"70%":"25%"
  };
  }

  
  getInput=(ev:{complete:boolean,type:string,value:string})=>{
    if(ev.complete){
      this.data['pin']=ev.value;
      this.data['disable']=false;
     // console.log(ev.value)
    }
    else{
      this.data['disable']=true;
    }
  }

  proceed=async ()=>{
    if(this.data.pin){
      this.data.side=false;
      //console.log(this.data)
      const payload={
        "amount":this.data.amount,
        "narration":this.data.narration,
        "type":"Bank" //or "Wallet"
        ,
        "account":this.data.accountNumber,
        "bankCode":this.data.bankCode, 
        "accountName":this.data.bankName,
        "pin":this.data.pin
    }
    try {
      this.action['data']['blocked']=true;
      const load=await this.action.Request('transaction/sendMoney',payload,'POST');
      this.action['data']['blocked']=false;
      if(!load.data.success){
        this.action.transactionResponse('error',{title:'Withdrawal failed',message:load.data.message},true,'proceed');
        return;
      }
      //transaction successful, load modal
     this.action.transactionResponse('success',{title:'Withdrawal successful',message:`You have withdrawn NGN ${this.data.amount} to your bank account`},true,'Done');
     this.action.history()
    } catch (error:any) {
      this.action['data']['blocked']=false;
      this.action.transactionResponse('error',{title:'Withdrawal failed',message:error.response?error.response.data.message:error},true,'proceed');
    }
    }
  }

}
