import { Component, AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, AfterViewChecked } from '@angular/core';
import { ActionsService } from '../services/actions.service';
@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent {
  data: any = {};
  constructor(
    public action: ActionsService,
    public change: ChangeDetectorRef
  ) {
    //test
    this.data['beneficiaries'] = [];

    this.data.beneficiaries.forEach((res: any, index: number) => {
      this.data['beneficiaries'][index]['img'] = `../../assets/skins/Ellipse${this.action.get_random(['175', '176', '178', '177'])}.svg`;
    })
  }

  ngOnInit(): void {
    // this.change.detectChanges();
  }

  async ngAfterContentInit(): Promise<void> {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    this.data['loading'] = true;
    try {
      const getter = await this.action.Request('transaction/getBanks', {}, 'GET');
      this.data.loading = false;
      if (getter.data.success) {
        this.data['banks'] = getter.data.data;
      }
      else {
        this.action.toast('error', getter.data.message)
      }
    } catch (error: any) {
      this.data['loading'] = false;
      this.action.toast('error', error)
    }
  }

  getAccountInfo = (ev: Event) => {
    // console.log(ev.target.onpaste())
    if (this.data.accountNo != undefined || this.data.accountNo != '') {
      const letter = /^-?\d+\.?\d*$/.test(this.data.accountNo);
      !letter ? this.data.accountNo = this.data.accountNo.slice(0, this.data.accountNo.length - 1, 10) : ''
      if (this.data.accountNo.length == 10 && this.data.selectedBank) {
        //fetch owner's info
        this.fetchAccount()
      }
    }
  }

  fetchAccount = async () => {
    if (this.data.accountNo) {
      //if(this.data.accountNo.length==10 && this.data.selectedBank){
      this.data['checkAccount'] = true;
      const payload = {
        "account": this.data.accountNo,
        "type": "Bank", //or "Wallet"
        "bankCode": this.data.selectedBank.code
      }
      try {
        const check = await this.action.Request('transaction/accountLookup', payload, 'POST');
        this.data['checkAccount'] = false;
        if (check.data.success) {
          this.data['accountInfo'] = check.data.data;
        }
        else {
          this.action.toast('error', 'Sorry an error occured, kindly check the account and try again')
        }

      } catch (error: any) {
        //console.log(error)
        this.data['checkAccount'] = false;
        this.action.toast('error', error.response ? error.response.data.data.responseMessage : error)
      }
    }
    //}
  }

  public doWidth = (): { width: string } => {
    const current = this.action.data.currentSize;
    return {
      width: current <= 768 ? "70vw" : "29vw"
    };
  }

  confirmDetails = () => {
    // console.log(this.data)
    const data = {
      ...this.data.accountInfo,
      amount: this.data.amount,
      bankName: this.data.selectedBank.name,
      narration: this.data.narration
    };
    this.action.saveItem(data);
    this.action.navigate('confirm')
  }
}
