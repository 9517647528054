<p-menubar [style]="{background:'#FFF'}">
  <ng-template pTemplate="start">
    <div class="flex">
      <i class="pi pi-bars hider" style="margin: auto;" (click)="action.data.defaultSide=false"></i>
      <h1 class="font-bold font-norm" style="margin-left: 5px;">Wallet</h1>
    </div>
  </ng-template>
  <ng-template pTemplate="end">
    <div class="flex">
      <div class="circle-head center-flex-c">
        <!-- <i class="pi pi-bell align-center"></i> -->
        <img src="../../../assets/skins/notification-bing.png" alt="">
      </div>
      <div style="margin-right: 10px;"><img src="{{data.user.profile_photo_url
                }}" alt="" (error)="defaultPhoto()" class="img"></div>
      <p class="font-bold font-norm align-center" style="margin-right: 10px;">{{data.user.first_name+"
        "+data.user.last_name}}</p>
    </div>
  </ng-template>
</p-menubar>