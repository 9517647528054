import { Component, AfterContentChecked, AfterContentInit } from '@angular/core';
import { ActionsService } from './services/actions.service';
import { SetpinsComponent } from './modals/setpins/setpins.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public action: ActionsService
  ) {
  }

  ngAfterContentInit(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    //console.log(this.action.getRouteParams('c'))
    setTimeout(async () => {
      if (this.action.getRouteParams('c') == null && this.action.secureGet('user')) {
        //console.log(this.action.secureGet('user'))
        const user = this.action.secureGet('user');
        if (user.hasTransactionPin == 0) {
          const id = user.id;
          this.action.saveItem({ type: 'transaction', id })
          //load login pin modal
          const loader = await this.action.modalCreate(SetpinsComponent, {
            closable: false,
            width: this.action.data.currentSize <= 768 ? "max-content" : "20%",
            height: 'auto',
            showHeader: false,
            data: {
              id
            }
          });
          if (loader.data.success) {
            const user: any = loader.data;
            this.action.secureSave(user, 'user');
            this.action.setRouteParams()
            this.action.data['init'] = true;
          }
        }
        else {
          this.action.setRouteParams();
          this.action.data['init'] = true;
        }
      }
      else if (this.action.getRouteParams('c') && this.action.secureGet('user')) {
        const id = this.action.getRouteParams('c');
        const user = this.action.secureGet('user');
        if (user.id != id) {
          localStorage.clear();
          this.action.createLoader('loading');
          //  console.log(this.action.secureGet('user'))
          this.action.Request('auth/revalidate', { id }, 'POST').then(async (res: any) => {
            // console.log(res)
            this.action.removeLoader();
            // console.log(res.data)
            if (res.data.success) {
              //check for the pin
              if (res.data.data.hasWalletPin) {
                this.action.saveItem({ type: 'login', id })
                //load login pin modal
                const loader = await this.action.modalCreate(SetpinsComponent, {
                  closable: false,
                  width: this.action.data.currentSize <= 768 ? "max-content" : "20%",
                  height: 'auto',
                  showHeader: false,
                  // header: 'Validate pin to proceed',

                  data: {
                    id
                  }
                });
                //console.log(loader)
                if (loader.data.success) {
                  const user: any = loader.data;
                  if (user.hasTransactionPin == 0) {
                    //load modal
                    this.action.saveItem({ type: 'transaction', id })
                    //load login pin modal
                    setTimeout(async () => {
                      const lo = await this.action.modalCreate(SetpinsComponent, {
                        closable: false,
                        width: this.action.data.currentSize <= 768 ? "max-content" : "20%",
                        height: 'auto',
                        showHeader: false,
                        data: {
                          id
                        }
                      });

                      if (lo.data.success) {
                        const user: any = lo.data;
                        this.action.secureSave(user, 'user');
                        this.action.setRouteParams()
                        this.action.data['init'] = true;
                      }
                    }, 200)
                  }
                  else {
                    // console.log(user)
                    this.action.secureSave(user, 'user');
                    this.action.setRouteParams()
                    this.action.data['init'] = true;
                  }
                }
              }
              else {
                this.action.saveItem({ type: 'setup', id })

                //load pin set modal
                const loader = await this.action.modalCreate(SetpinsComponent, {
                  closable: false,
                  width: this.action.data.currentSize <= 768 ? "max-content" : "20%",
                  height: 'auto',
                  showHeader: false,
                  data: {
                    id
                  }
                });
                //console.log(loader)
                if (loader.data.success) {
                  const user: any = loader.data;
                  if (user.hasTransactionPin == 0) {
                    //load modal
                    this.action.saveItem({ type: 'transaction', id })
                    //load login pin modal
                    setTimeout(async () => {
                      const lo = await this.action.modalCreate(SetpinsComponent, {
                        closable: false,
                        width: '20%',
                        height: 'auto',
                        // header: 'Proceed to set up your transaction pin',
                        showHeader: false,
                        data: {
                          id
                        }
                      });

                      if (lo.data.success) {
                        const user: any = lo.data;
                        this.action.secureSave(user, 'user');
                        this.action.setRouteParams()
                        this.action.data['init'] = true;
                      }
                    }, 200)
                  }
                  else {
                    // console.log(user)
                    this.action.secureSave(user, 'user');
                    this.action.setRouteParams()
                    this.action.data['init'] = true;
                  }
                }
              }
              //   const user=res.data.data;
              //   user['token']=res.data.token;
              //  // console.log(user)
              //   this.action.secureSave(user,'user');
              //   this.action.setRouteParams()
              //   this.action.data['init']=true;
            }
            else {
              this.action.history()
            }
          }).catch((err) => {
            this.action.history()
            this.action.removeLoader();
            this.action.toast('error', err)

          })
        }
        else {
          this.action.setRouteParams();
          this.action.data['init'] = true;
        }
      }
      else {
        const id = this.action.getRouteParams('c');
        this.action.createLoader('loading');
        // console.log(this.action.secureGet('user'))
        this.action.Request('auth/revalidate', { id }, 'POST').then(async (res: any) => {
          // console.log(res)
          this.action.removeLoader();
          // console.log(res.data)
          if (res.data.success) {
            if (res.data.data.hasWalletPin) {
              //  console.log('here')
              this.action.saveItem({ type: 'login', id })
              //load login pin modal
              const loader = await this.action.modalCreate(SetpinsComponent, {
                closable: false,
                width: this.action.data.currentSize <= 768 ? "max-content" : "20%",
                height: 'auto',
                showHeader: false,
                // header: 'Validate pin to proceed',
                data: {
                  id
                }
              });
              //console.log(loader)
              if (loader.data.success) {
                const user: any = loader.data;
                if (user.hasTransactionPin == 0) {
                  //load modal
                  this.action.saveItem({ type: 'transaction', id })
                  //load login pin modal
                  setTimeout(async () => {
                    const lo = await this.action.modalCreate(SetpinsComponent, {
                      closable: false,
                      width: this.action.data.currentSize <= 768 ? "max-content" : "20%",
                      height: 'auto',
                      showHeader: false,
                      data: {
                        id
                      }
                    });
                    if (lo.data.success) {
                      const user: any = lo.data;
                      this.action.secureSave(user, 'user');
                      this.action.setRouteParams()
                      this.action.data['init'] = true;
                    }

                  }, 200)
                }
                else {
                  // console.log(user)
                  this.action.secureSave(user, 'user');
                  this.action.setRouteParams()
                  this.action.data['init'] = true;
                }
              }
            }
            else {
              //console.log('here')
              this.action.saveItem({ type: 'setup', id })
              //load pin set modal
              const loader = await this.action.modalCreate(SetpinsComponent, {
                closable: false,
                width: this.action.data.currentSize <= 768 ? "max-content" : "20%",
                height: 'auto',
                showHeader: false,
                data: {
                  id
                }
              });
              //console.log(loader)
              if (loader.data.success) {
                const user: any = loader.data;
                if (user.hasTransactionPin == 0) {
                  //load modal
                  this.action.saveItem({ type: 'transaction', id })
                  //load login pin modal
                  setTimeout(async () => {
                    const lo = await this.action.modalCreate(SetpinsComponent, {
                      closable: false,
                      width: this.action.data.currentSize <= 768 ? "max-content" : "20%",
                      height: 'auto',
                      showHeader: false,
                      data: {
                        id
                      }
                    });

                    if (lo.data.success) {
                      const user: any = lo.data;
                      this.action.secureSave(user, 'user');
                      this.action.setRouteParams()
                      this.action.data['init'] = true;
                    }
                  }, 200)
                }
                else {
                  // console.log(user)
                  this.action.secureSave(user, 'user');
                  this.action.setRouteParams()
                  this.action.data['init'] = true;
                }
              }

            }

          }
          else {
            // this.action.history()
          }
        }).catch((err) => {
          //console.log(err)
          this.action.removeLoader();
          this.action.toast('error', err.response ? err.response.data.message : err)
          // console.log(err)
          // this.action.history()
        })
      }
    }, 200)
  }
  title = 'hr56Wallet';
}
