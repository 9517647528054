<div class="flex-n" *ngIf="!data.loading">
  <div>
    <div class="m-t" (click)="action.history()">
      <p-button label="Back" [outlined]="true" severity="secondary" [rounded]="true" icon="pi pi-angle-left"
        size="small"></p-button>
    </div>
    <div *ngIf="data.type=='cable'">
      <h1 class="font-bold font-big ">TV/ Cable Subscription Payment</h1>
      <p class="font-light font-fade font-small">Make payments to your favourite cable TV.</p>
    </div>
    <div *ngIf="data.type=='power'">
      <h1 class="font-bold font-big">Electricity</h1>
      <p class="font-light font-fade font-small">Make payments to your electricity bills</p>
    </div>
    <div *ngIf="data.type=='internet'">
      <h1 class="font-bold font-big">Top up airtime/data</h1>
      <p class="font-light font-fade font-small">Make payments to your internet providers</p>
    </div>
    <div class="box-special">
      <div *ngFor="let item of data.providers" class="flex-full m-b-lg cursor" (click)="go(item)">
        <div class="flex">
          <img src="{{item.avatar}}" alt="" class="imgs">
          <p class="font-bold align-center font-big">{{item.name}}</p>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 25 24" fill="none">
            <path
              d="M9.4101 20.6734C9.2201 20.6734 9.0301 20.6034 8.8801 20.4534C8.5901 20.1634 8.5901 19.6834 8.8801 19.3934L15.4001 12.8734C15.8801 12.3934 15.8801 11.6134 15.4001 11.1334L8.8801 4.61344C8.5901 4.32344 8.5901 3.84344 8.8801 3.55344C9.1701 3.26344 9.6501 3.26344 9.9401 3.55344L16.4601 10.0734C16.9701 10.5834 17.2601 11.2734 17.2601 12.0034C17.2601 12.7334 16.9801 13.4234 16.4601 13.9334L9.9401 20.4534C9.7901 20.5934 9.6001 20.6734 9.4101 20.6734Z"
              fill="#292D32" />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="d-auto">
    <img src="../../assets/skins/hand.png" alt="">
  </div>
</div>

<div *ngIf="data.loading">
  <div class="center-flex-n">
    <div class="loading"></div>
  </div>
</div>