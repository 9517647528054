import { Component, AfterContentInit, ViewChild, ElementRef, Renderer2, AfterContentChecked } from '@angular/core';
import { ActionsService } from '../services/actions.service';
import { CurrencyPipe } from '@angular/common';


@Component({
  selector: 'app-bills-menu',
  templateUrl: './bills-menu.component.html',
  styleUrls: ['./bills-menu.component.scss']
})
export class BillsMenuComponent {
  @ViewChild('scrollContainer') private scrollContainer!: ElementRef;
  data: any = {};
  constructor(
    public action: ActionsService,
    private renderer: Renderer2,
    private currencyPipe: CurrencyPipe
  ) {
    this.data['disable'] = true;
  }


  async ngAfterContentInit(): Promise<void> {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    if (!this.action.getItem()) {
      this.action.history()
    }
    else {
      this.data['item'] = this.action.getItem()
      //get items from biller
      try {
        if (this.data.item.type == 'cable') {
          this.data['loading'] = true;
          const get = await this.action.Request('billpayment/cable/plans', { serviceType: this.data.item.serviceType }, 'POST');
          this.data.loading = false;
          if (!get.data.success) {
            this.action.toast('error', get.data.message);
            return
          }
          this.data['info'] = get.data.data;
        }
        else {
          this.data['selectedItem'] = this.data.item;

          //for electricity, don't load anything
          // console.log(this.data.selectedItem)
        }
        //console.log(this.data.info)
      } catch (error: any) {
        this.data.loading = false;
        this.action.toast('error', error, 'An error occured')
      }
    }
  }

  checkUser = async (ev: any) => {
    const value = (typeof ev != "string" && ev) ? ev.target.value.toString() : (typeof ev == "string" && ev) ? ev.toString() : ""
    if (value.length >= '10') {
      this.data['user'] = null;
      try {
        this.data['busy'] = true;
        // console.log(this.data.item)
        const payload = this.data.item.type == 'cable' ? {
          "billerId": this.data.item.billerId,
          "productId": this.data.item.productId,
          "customerNo": value,
          "serviceCategory": 'CABLETV',
          "serviceType": this.data.item.serviceType
        } : {
          "billerId": this.data.selectedItem.billerId,
          "productId": this.data.selectedItem.productId,
          "customerNo": value,
          "serviceCategory": 'POWER',
          "serviceType": this.data.selectedItem.serviceType
        }
        // console.log('payload',payload)
        const caller = await this.action.Request('billpayment/validateUser', payload, 'POST');
        this.data.busy = false;
        this.data['user'] = caller.data;
        //console.log(caller.data)
        //scroll to bottom
        setTimeout(() => {
          this.scrollToBottom()
        }, 200)
        //console.log(caller.data)
      } catch (error: any) {
        this.data.busy = false;
        this.action.toast('error', error, 'An error occured')
      }
    }
  }

  scrollToBottom(): void {
    try {
      //   const element = this.scrollContainer.nativeElement;
      // //  this.renderer.setProperty(element, 'scrollTop', element.scrollHeight);
      //   this.data['height']= element.scrollHeight;
      //   console.log(this.data.height)
      const element = this.action.ids("focusBtn");
      element?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    } catch (err) {
      console.error(err);
    }
  }

  select = (item: {}, id: number) => {
    //first remove all
    this.data.info.forEach((e: any, index: number) => {
      const id = this.action.ids(index.toString());
      id?.classList.contains('selected') ? id.classList.remove('selected') : '';
    });
    this.action.ids(id.toString())?.classList.add('selected');
    //proceed
    this.data['selected'] = item;
    setTimeout(() => {
      this.scrollToBottom()
    }, 200)
    //console.log(item)
  }

  public doWidth = (): { width: string } => {
    const current = this.action.data.currentSize;
    //console.log(current)
    return {
      width: current <= 768 ? "70vw" : "29vw"
    };
  }


  getInput = (ev: { complete: boolean, type: string, value: string }) => {
    if (ev.complete) {
      this.data['pin'] = ev.value;
      this.data['disable'] = false;
      // console.log(ev.value)
    }
    else {
      this.data['disable'] = true;
    }
  }

  proceedPay = async () => {
    this.data.side = false;
    const item = this.data.item.type == 'cable' ? {
      payload: {
        "billerId": this.data.item.billerId,
        "billerName": this.data.item.name.toUpperCase(),
        "customerNo": this.data.cardNo.toString(),
        "serviceCategory": "CABLETV",
        "amount": this.data.selected.price,
        "paymentCode": this.data.selected.paymentCode,
        "customerName": this.data.user.customerName,
        "billerCode": this.data.item.name,
        pin: this.data.pin
      },
      url: 'billpayment/buyCableTv'
    } : {
      payload: {
        "billerId": this.data.selectedItem.billerId,
        "billerName": this.data.selectedItem.name.toUpperCase(),
        "customerNo": this.data.meterNo,
        "serviceCategory": "POWER",
        "amount": this.data.amount,
        "customerName": this.data.user.customerName,
        "serviceType": this.data.selectedItem.serviceType,
        "customerAddress": this.data.user.customerAddress,
        "notificationEmail": this.data.email,
        "notificationPhone": this.data.phone
      },
      url: 'billpayment/buyElectricity'
    }
    //console.log(item)
    try {
      this.action.data['blocked'] = true;
      const load = await this.action.Request(item.url, item.payload, 'POST');
      this.action.data.blocked = false;
      if (!load.data.success) {
        this.action.transactionResponse('error', { title: 'Cable tv purchase failed', message: load.data.message }, true, 'proceed');
        return;
      }
      //console.log(load.data.data)
      //transaction successful, load modal
      this.data.item.type == 'cable' ? this.action.transactionResponse('success', { title: 'Cable tv purchase successful', message: `You have successfully made cable tv payment of ${item.payload?.amount}` }, true, 'Done') : this.action.transactionResponse('success', { title: 'Electricity purchase successful', message: `You have successfully purchased N${item.payload?.amount} of electricity, Your token is: ${load.data.data.purchaseToken}` }, true, 'Done')
      this.action.history()
    } catch (error: any) {
      // console.log(error)
      this.action['data']['blocked'] = false;
      this.data.item.type == 'cable' ? this.action.transactionResponse('error', { title: 'Cable tv purchase failed', message: error.response ? error.response.data.message : error }, true, 'proceed') : this.action.transactionResponse('error', { title: 'Electricity purchase failed', message: error.response ? error.response.data.message : error }, true, 'proceed')
    }
  }

  confirmElect = async () => {
    const modal = await this.action.paymentConfirmationModal(
      {
        width: this.action.data.currentSize <= 780 ? "80%" : '30%',
        height: 'auto',
        header: 'Confirm details'
      },
      `${this.currencyPipe.transform(parseInt(this.data.amount), 'NGN')}`,
      {
        Billername: this.data.selectedItem.billerId,
        'Payment Item': this.data.selectedItem.name,
        'Meter Number': this.data.meterNo,
        'Meter Name': this.data.user.customerName,
        'Address': this.data.user.customerAddress
      },
      true,
      {
        'from wallet balance': ''
      },
      true,
      'Confirm to pay'
    );
    this.data['side'] = true;
  }
}
