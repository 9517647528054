import { Component, NgZone, HostListener, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import axios, { AxiosResponse } from 'axios';
import { format, parseISO, getDate, getMonth, getYear } from 'date-fns';
import { Router, ActivatedRoute, ParamMap, NavigationExtras, RouterEvent } from '@angular/router';
import { Location, LocationStrategy, DOCUMENT } from '@angular/common';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService, ConfirmEventType, Confirmation } from 'primeng/api';
import * as jwt from 'jsonwebtoken';
import { filter } from 'rxjs';
import { ResponseComponent } from '../globals/response/response.component';
import { Renderer2 } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ConfirmationComponent } from '../modals/confirmation/confirmation.component';
//import { nanoid } from 'nanoid'
declare var require: any;
interface dats {
}
@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  public data: any = {};

  constructor(
    public route: Router,
    public zone: NgZone,
    public _location: Location,
    public modal: DialogService,
    public confirm: ConfirmationService,
    public msg: MessageService,
    public routeP: ActivatedRoute,
    public dialogConf: DynamicDialogConfig,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.data['modals'] = [];
    this.data['secret'] = 'bridgegap';
    this.data['defaultSide'] = true;
    this.data['currentSize'] = window?.innerWidth;
    //this.data['currentSize']=this.el.nativeElement.innerWidth;
    //console.log(this.el.nativeElement.innerWidth)
    window.addEventListener('resize', (ev: any) => {
      this.data['currentSize'] = ev.target.innerWidth;
      if (ev.target.innerWidth <= 768) {
        //this.data.defaultSide=false
      }
      else {
        // this.data.defaultSide=true
      }
    })

    this.route.events.pipe(
      filter((e: Event | RouterEvent | any): e is RouterEvent => e instanceof RouterEvent)
    ).subscribe((e: RouterEvent) => {
      // Do something
      this.data['current'] = e.url;
    })

  }

  // onResize(event: Event): void {
  //   // Handle the resize event
  //   console.log(event);
  // }

  init = () => {
    //set headers
    let headers: any = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    //get the authororization
    this.secureGet('user') != null ? headers['authorization'] = 'Bearer ' + this.secureGet('user').token : '';
    this.data['headers'] = headers;

    //live url
    //this.data['url']='https://services.titan-xchange.com/api/v2/';

    //local url
    //this.data['url'] = 'http://127.0.0.1:8000/api/';
    this.data['url'] = 'https://new.hr56.org/api/';

    //test server url
    //this.data['url'] = 'https://9110.titan-xchange.com/api/v1/';
    this.data['server'] = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/';
    //console.log(this.data.server);

  };

  doWidth = (type: "dropdown" | "input" | "button") => {
    let width;

    switch (type) {
      case 'dropdown':
        const tryer = {
          width: this.data.currentSize <= 768 ? "80vw" : "29vw"
        }
        //console.log(tryer)
        return tryer
        break;
      case 'button':
        const tryer2 = {
          width: this.data.currentSize <= 768 ? "85vw" : "29vw"
        }
        //console.log(tryer)
        return tryer2
        break;
      case 'input':
        const tryer3 = {
          width: this.data.currentSize <= 768 ? "80vw" : "29vw"
        }
        //console.log(tryer)
        return tryer3
        break;

      default:
        break;

    }
    return width
    // console.log(width)
    // return width;
  }

  secureGet = (name: string) => {
    const token = require('jsonwebtoken');
    //  console.log(this.data.secret);
    if (isPlatformBrowser(this.platformId)) {
      // Client only code. Local storage code
      return localStorage.getItem(`bridgegapWallet-${name}`) != null ? token.verify(localStorage.getItem(`bridgegapWallet-${name}`), this.data.secret.toString()) : null;
    }
  }

  checkBrowser = (): boolean => {
    return (isPlatformBrowser(this.platformId))
  }

  secureSave = (data: any, name: string) => {
    try {
      const sign = jwt.sign(data, this.data.secret);
      //console.log('here',sign)
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(`bridgegapWallet-${name}`, sign);
      }
    } catch (error) {
      console.log(error)
    }
    //console.log('called',data)
    // const token=require('jsonwebtoken');
    // console.log(token)
    //     const secured=token.sign('m','123',(err:any,code:any)=>{
    // console.log('error',code)
    //     });
  }

  checkFullURL = (action: string): Promise<string> => {
    //call the init function
    this.init();
    return new Promise((resolve, reject) => {
      if (action.indexOf('http://') > -1 || action.indexOf('https://') > -1) {
        resolve(action);
      }
      else {
        resolve(this.data.url + action);
      }
    })
  }

  //make secured t2t post request here
  async Request(action: string, data: dats = {}, method: "POST" | "GET" | "PATCH" | "DELETE" | "PUT"): Promise<AxiosResponse> {
    //pass the data as a json web token first
    return new Promise((resolve, reject) => {
      //  console.log(this.checkFullURL(action))
      this.checkFullURL(action).then((res: any) => {
        // const token=require('jsonwebtoken');
        const itemer: any = data;
        itemer['url'] = res
        //  console.log(data)
        const payload = jwt.sign(data, this.data.secret);
        const spec = {
          payload: payload,
          headers: this.data.headers,
          method: method
        };
        axios.request({
          method: 'POST',
          timeout: 1200000,
          url: `${this.data.server}api`,
          headers: this.data.headers,
          data: spec
        }).then((res: any) => {
          if (isPlatformBrowser(this.platformId)) {
            const handle = jwt.verify(res.data, this.data.secret);
            res['data'] = handle;
            //console.log(res)
            resolve(res);
          }
        }).catch((err: any) => {
          if (isPlatformBrowser(this.platformId)) {
            const handle = jwt.verify(err.response.data, this.data.secret);
            err.response ? err.response['data'] = handle : ''
            // console.log(err)
            reject(err)
          }
          // const handle=jwt.verify(err.response.data,this.data.secret);
          const handle = 'sdio'

        })
      }).catch(err => {

        reject(err);
      })
    })
  }

  async RequestNoSecure(action: string, data: dats = {}, method: "POST" | "GET" | "PATCH" | "DELETE" | "PUT"): Promise<AxiosResponse> {
    //pass the data as a json web token first
    return new Promise((resolve, reject) => {
      //  console.log(this.checkFullURL(action))
      this.checkFullURL(action).then((res: any) => {
        axios.request({
          method: method,
          timeout: 1200000,
          url: res,
          headers: this.data.headers,
          data: data
        }).then((res: any) => {
          // const handle=token.verify(res.data,this.data.secret);
          //   res['data']=handle;
          // console.log(res)
          resolve(res);
        }).catch((err: any) => {
          //  const handle=token.verify(err.response.data,this.data.secret);
          // err.response['data']=handle;
          reject(err)
        })
      }).catch(err => {
        reject(err);
      })
    })
  }

  modalCreate = (component: any, config: DynamicDialogConfig = {}): Promise<{
    data: {
      success: boolean,
      data: {}
    }
  }> => {
    return new Promise((resolve, reject) => {
      this.data['modal'] = this.modal.open(component, config);
      this.data['modals'].push(this.data.modal)
      this.data.modal.onClose.subscribe((res: any) => {
        resolve(res)
      })
    })
  }

  modalClose = (data: { success: boolean, data: {} }) => {
    this.data.modal.close({
      data: {
        ...data
      }
    })
  }

  saveItem(item: any) {
    this.data['item'] = item;
    return "succces";
  }

  getItem() {
    return this.data.item;
  }

  copy = (no: string = "", name: string = "Account number") => {

    navigator.clipboard.writeText(no);
    // this.
    this.toast('success', name + " copied to clipboard");

  }

  formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy')
  }

  formatTime(value: string) {
    return format(parseISO(value), 'HH:mm');
  }

  generateId() {
    //return  nanoid();
  }

  calcTotal = (res: Array<any>) => {
    return new Promise((resolve, reject) => {
      resolve(res.reduce((accumulator: any, item: any) => accumulator + parseInt(item.amount), 0))
    })
  }

  navigate = (path: string) => {
    const token = this.secureGet('user') != null;
    this.route.navigateByUrl(path);
    this.data.defaultSide = true;
  }

  navigateExtra = (path: string, extra: {}) => {
    const extras: NavigationExtras = {
      state: {
        ...extra
      }
    }
    this.route.navigateByUrl(path, extras)
  }

  getNavItem = () => {
    //get navigation parameters here
    const item = this.route.getCurrentNavigation()?.extras.state;
    return item;
  }


  public transactionResponse = async (type: 'success' | 'error', data: {
    title: string,
    message: string,
  }, showButton: boolean = false, buttonLabel: string = ''): Promise<void> => {
    await this.modalCreate(ResponseComponent, {
      width: this.data.currentSize <= 780 ? "80%" : '30%',
      height: 'auto',
      data: { ...data, type, showButton, buttonLabel }
    });
  }

  public paymentConfirmationModal = async (
    config: DynamicDialogConfig,
    header: string,
    body: {},
    showFooter: boolean = false,
    footer: object = {},
    showButton: boolean = false,
    buttonLabel: string = ''
  ): Promise<void> => {
    const data: any = {
      header,
      body
    }
    showFooter ? (data['showFooter'] = showFooter, data['footer'] = footer) : '';
    showButton ? (data['showButton'] = showButton, data['buttonLabel'] = buttonLabel) : '';
    await this.modalCreate(ConfirmationComponent, {
      ...config,
      data
    });
  }


  getRouteParams = (item: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(item)
  }

  setRouteParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.set('', '');
  }

  history = () => {
    this._location.back();
  }
  //toast here
  toast = (sev: "warning" | "success" | "error", mes: string, sum: string = '') => {
    this.msg.add({ severity: sev, detail: mes, summary: sum })
  }

  ids = (id: string) => {
    return document.getElementById(id);
  }

  createLoader = (message: string) => {
    this.data['blocked'] = true;
    this.data['loadingMessage'] = message;
  }

  removeLoader = () => {
    this.data['blocked'] = false;
    this.data['loadingMessage'] = null;
  }


  alertConfirm = (message: string, custom: boolean = false, params: Confirmation = { message: '' }) => {
    //  console.log(custom)
    return new Promise((resolve, reject) => {

      const payload = !custom ? {
        //target: ev.target,
        message: message,
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-warning',
        rejectButtonStyleClass: 'p-button-white',
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      } : params;
      this.confirm.confirm(payload);
    });
  }

  get_random = (list: Array<string>) => {
    return list[Math.floor((Math.random() * list.length))];
  }
}
