<p-sidebar [(visible)]="data.side" position="right" [style]="doWidth()">
  <div class="space" *ngIf="data.current=='wallet'">
    <div class="center-flex">
      <div class="r-box mg-b">
        <img src="assets/skins/transf.png" alt="">
      </div>
    </div>
    <div>
      <h1 class="font-bold align-center font-big"><strong>Bank Transfer</strong></h1>
      <div class="font-light font-fade font-norm align-center">Money transfers sent to this bank account number</div>
      <div class="font-light font-fade font-norm align-center">will
        automatically
        fund your bank</div>
      <div class="font-light font-fade font-norm align-center"> account.</div>
    </div>
    <div class="box-s">
      <div class="flex-full mg-b">
        <div class="font-light font-norm">Bank name: </div>
        <div class="font-bold font-norm"><strong>{{data?.wallet?.bankName}}</strong></div>
      </div>
      <div class="flex-full mg-b">
        <div class="font-light font-norm">Account number: </div>
        <div class="font-bold font-norm"><strong>{{data?.wallet?.accountNumber}}</strong></div>
      </div>
      <div class="flex-full mg-b">
        <div class="font-light font-norm">Account name: </div>
        <div class="font-bold font-norm"><strong>{{data?.wallet?.accountName}}</strong></div>
      </div>
    </div>
    <div>
      <!-- <p-button label="Copy account details" [rounded]="true" [style]="{background:'#56006B',width:'400px'}"
        *ngIf="data.loading" [loading]="data.loading"></p-button> -->
      <button class="p-button" pButton class="font-bold butt align-center" [loading]="data.loading"
        (click)="action.copy(data?.wallet?.accountNumber)">Copy account details &nbsp; <i
          class="pi pi-copy"></i></button>
    </div>
  </div>
  <div *ngIf="data.current=='ussd'">
    <div class="">
      <h1 class="font-bold font-big">Fund With USSD</h1>
      <p class="font-light font-norm">
        Type in the amount you want to add to your bank account and tap the right USSD code below to dial it.
      </p>
    </div>
    <div>
      <div class="container">
        <img class="input-icon" src="../../assets/skins/Ell.svg" alt="icon">
        <div class="font-light m-f font-norm font-fade">NGN</div>
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="22" viewBox="0 0 2 22" fill="none">
          <path d="M1 0.5L1 21.5" stroke="#AAAAAA" stroke-width="0.5" stroke-linecap="round" />
        </svg>
        <input class="input-field" type="tel" placeholder="amount" [(ngModel)]="data.amount" (keyup)="loadUssd()">
      </div>
    </div>
    <div *ngFor="let item of data.ussd" class="flex cent mg-b" (click)="action.copy(item.code,'Ussd code')">
      <div class="circle-sm m-f" [style]="{background:item.bg}">
        <img src="{{item.img}}" alt="">
      </div>
      <div>
        <div class="font-light font-norm">{{item.name}}</div>
        <div class="font-bold font-norm"><strong>{{item.code}}</strong></div>
      </div>
    </div>
  </div>
</p-sidebar>

<div class="flex-n">
  <div>
    <div class="m-t" (click)="action.history()">
      <p-button label="Back" [outlined]="true" severity="secondary" [rounded]="true" icon="pi pi-angle-left"
        size="small"></p-button>
    </div>
    <div>
      <h1 class="font-bold font-big">Fund wallet</h1>
    </div>
    <div style="max-width:1230px;">
      <div class="flex-n">
        <div class="box p-sm mg-r bg-img" (click)="getWallet()">
          <div class="flex-full">
            <div class="flex">
              <div class="circle-g bg-dr center-flex mg-r">
                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                  <path
                    d="M34.1161 29.5036V34.1083H3.41846V29.5036C3.41846 28.6594 4.10915 27.9688 4.95334 27.9688H32.5813C33.4254 27.9688 34.1161 28.6594 34.1161 29.5036Z"
                    fill="#039847" stroke="#039847" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M11.0927 17.2344H8.02295V27.9786H11.0927V17.2344Z" fill="#039847" />
                  <path d="M17.2324 17.2344H14.1626V27.9786H17.2324V17.2344Z" fill="#039847" />
                  <path d="M23.372 17.2344H20.3022V27.9786H23.372V17.2344Z" fill="#039847" />
                  <path d="M29.5117 17.2344H26.4419V27.9786H29.5117V17.2344Z" fill="#039847" />
                  <path
                    d="M35.651 35.2555H1.88358C1.25428 35.2555 0.732422 34.7336 0.732422 34.1043C0.732422 33.475 1.25428 32.9531 1.88358 32.9531H35.651C36.2803 32.9531 36.8022 33.475 36.8022 34.1043C36.8022 34.7336 36.2803 35.2555 35.651 35.2555Z"
                    fill="#039847" />
                  <path
                    d="M33.1492 9.16455L19.3352 3.63897C19.0282 3.51618 18.5064 3.51618 18.1994 3.63897L4.38543 9.16455C3.84822 9.37943 3.41846 10.0087 3.41846 10.592V15.6878C3.41846 16.532 4.10915 17.2227 4.95334 17.2227H32.5813C33.4254 17.2227 34.1161 16.532 34.1161 15.6878V10.592C34.1161 10.0087 33.6864 9.37943 33.1492 9.16455ZM18.7673 13.3855C17.4933 13.3855 16.465 12.3571 16.465 11.0832C16.465 9.8092 17.4933 8.78083 18.7673 8.78083C20.0412 8.78083 21.0696 9.8092 21.0696 11.0832C21.0696 12.3571 20.0412 13.3855 18.7673 13.3855Z"
                    fill="#039847" />
                </svg>
              </div>
              <div style="margin: auto;">
                <div class="font-bold font-big"><strong>Bank Transfer</strong></div>
                <div class="font-light font-small font-fade">Add money via mobile or internet banking</div>
              </div>

            </div>
            <div>
              <i class="pi pi-angle-right align-center cursor m-t"></i>
            </div>
          </div>
        </div>
        <div class="box p-sm mg-r bg-img" (click)="this.data.current='ussd'; this.data.side=true;">
          <div class="flex-full">
            <div class="flex">
              <div class="circle-g bg-dr center-flex mg-r">
                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                  <path
                    d="M15.0098 2.67757C15.2338 2.71315 15.4487 2.79254 15.6421 2.91121C15.8355 3.02988 16.0036 3.18549 16.1368 3.36916C16.27 3.55283 16.3657 3.76095 16.4184 3.98163C16.4712 4.20231 16.4799 4.43121 16.4441 4.65526L15.4748 10.7127H24.6381L25.6972 4.10961C25.7849 3.67203 26.0386 3.28541 26.4052 3.03081C26.7717 2.77622 27.2226 2.67343 27.6632 2.744C28.1039 2.81456 28.5002 3.05301 28.7689 3.40933C29.0376 3.76565 29.1579 4.21217 29.1046 4.65526L28.1376 10.7127H33.1567C33.6147 10.7127 34.0539 10.8946 34.3777 11.2184C34.7015 11.5423 34.8834 11.9815 34.8834 12.4394C34.8834 12.8974 34.7015 13.3366 34.3777 13.6604C34.0539 13.9842 33.6147 14.1662 33.1567 14.1662H27.5851L26.1116 23.3755H32.0055C32.4635 23.3755 32.9027 23.5574 33.2265 23.8812C33.5503 24.2051 33.7323 24.6443 33.7323 25.1022C33.7323 25.5602 33.5503 25.9994 33.2265 26.3232C32.9027 26.647 32.4635 26.829 32.0055 26.829H25.559L24.4999 33.4343C24.4122 33.8719 24.1585 34.2585 23.792 34.5131C23.4254 34.7677 22.9745 34.8705 22.5339 34.7999C22.0932 34.7294 21.6969 34.4909 21.4282 34.1346C21.1595 33.7783 21.0392 33.3318 21.0925 32.8887L22.0595 26.829H12.8962L11.8372 33.4343C11.7494 33.8719 11.4957 34.2585 11.1292 34.5131C10.7626 34.7677 10.3117 34.8705 9.87107 34.7999C9.43039 34.7294 9.03416 34.4909 8.76544 34.1346C8.49673 33.7783 8.37641 33.3318 8.42972 32.8887L9.39669 26.829H4.37762C3.91966 26.829 3.48046 26.647 3.15663 26.3232C2.8328 25.9994 2.65088 25.5602 2.65088 25.1022C2.65088 24.6443 2.8328 24.2051 3.15663 23.8812C3.48046 23.5574 3.91966 23.3755 4.37762 23.3755H9.94925L11.4227 14.1662H5.52879C5.07082 14.1662 4.63162 13.9842 4.30779 13.6604C3.98397 13.3366 3.80204 12.8974 3.80204 12.4394C3.80204 11.9815 3.98397 11.5423 4.30779 11.2184C4.63162 10.8946 5.07082 10.7127 5.52879 10.7127H11.9753L13.0344 4.10961C13.1068 3.65794 13.3555 3.25346 13.7258 2.98497C14.0962 2.71648 14.558 2.60592 15.0098 2.67757ZM22.612 23.3755L24.0855 14.1662H14.9223L13.4488 23.3755H22.612Z"
                    fill="#039847" />
                </svg>
              </div>
              <div style="margin: auto;">
                <div class="font-bold font-big"><strong>USSD</strong></div>
                <div class="font-light font-small font-fade">Add money via mobile or internet banking</div>
              </div>

            </div>
            <div>
              <i class="pi pi-angle-right align-center cursor m-t"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="center-flex">
        <div class="m-auto">
          <img src="assets/skins/hand.png" alt="">
        </div>
      </div>
      <div>
        <h1 class="font-light align-center font-big font-fade">Fund Wallet through the available channels</h1>
      </div>
    </div>
  </div>
</div>