import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import FundComponent from './fund/fund.component';
import { TransferComponent } from './transfer/transfer.component';
import { PayConfirmComponent } from './pay-confirm/pay-confirm.component';
import { BillsPayComponent } from './bills-pay/bills-pay.component';
import { BillsMenuComponent } from './bills-menu/bills-menu.component';
import { AirtimeDataComponent } from './airtime-data/airtime-data.component';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
const routes: Routes = [
  {path:'',component:HomeComponent},
  {path:'home',component:HomeComponent},
  {path:'fund',component:FundComponent},
  {path:'transfer',component:TransferComponent},
  {path:'confirm',component:PayConfirmComponent},
  {path:'Bills',component:BillsPayComponent},
  {path:'billsMenu',component:BillsMenuComponent},
  {path:'airtime',component:AirtimeDataComponent},
  {path:'details',component:TransactionDetailsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
