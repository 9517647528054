<div *ngIf="data.loading">
  <div class="center-flex-n">
    <div class="loading"></div>
  </div>
</div>
<div *ngIf="!data.loading">
  <div>
    <div class="m-t" (click)="action.history()">
      <p-button label="Back" [outlined]="true" severity="secondary" [rounded]="true" icon="pi pi-angle-left"
        size="small"></p-button>
    </div>
    <div>
      <h1 class="font-big">Transaction details</h1>
      <p class="font-fade font-small">Filter and track transactions here.</p>
    </div>

    <div class="center-flex-c">
      <div class="new-box">
        <div class="font-big align-center">
          <strong>{{data?.details?.amount | currency:'NGN'}}</strong>
        </div>
        <div class="m-b-lg">
          <p [ngClass]="{'red': data.details.status=='Failed','pending':data.details.status=='Pending','success':data.details.status=='Successful'}"
            class="align-center font-norm"><span *ngIf="data.details.status=='Successful'"><i
                class="pi pi-check-circle"></i></span>
            &nbsp;&nbsp; {{data.details.status}}</p>
        </div>
        <div *ngIf="data.details.status=='Successful'" class="flex-full-s">
          <div class="success">
            <i class="pi pi-check-circle align-center"></i>
            <p class="align-center font-norm">Payment Successful</p>
            <div class="font-small align-center">
              {{data.details.created_at | date}}
            </div>
          </div>

          <div class="line success"></div>

          <div class="success">
            <i class="pi pi-check-circle align-center"></i>
            <p class="align-center font-norm">Submitted to Bank</p>
            <div class="font-small align-center">
              {{data.details.created_at | date}}
            </div>
          </div>

          <div class="line success"></div>

          <div class="success">
            <i class="pi pi-check-circle align-center"></i>
            <p class="align-center font-norm">Received by Bank</p>
            <div class="font-small align-center">
              {{data.details.updated_at | date}}
            </div>
          </div>
        </div>

        <div>
          <p *ngIf="data.details.status=='Successful'" class="font-norm font-fade">
            The transfer has been successfully paid and submitted to the recipient bank.
            Kindly note the actual credit that time is subject to the bank.
          </p>
          <p *ngIf="data.details.status=='pending'" class="font-norm font-fade">
            This transaction is still pending
          </p>
          <p *ngIf="data.details.status=='Reversed'" class="font-norm font-fade">
            This transaction has been reversed into your account
          </p>
        </div>

        <div>
          <div class="flex-full-s m-b-lg">
            <p class="font-norm"><strong>
                Transfer Amount
              </strong></p>
            <div class="font-norm">
              {{data.details.amount | currency:'NGN'}}
            </div>
          </div>
          <div class="flex-full-s m-b-lg">
            <p class="font-norm"><strong>
                Fee
              </strong></p>
            <div class="font-norm">
              {{data.details.charge| currency:'NGN'}}
            </div>
          </div>
          <div class="flex-full-s m-b-lg">
            <p class="font-norm"><strong>
                Payment
              </strong></p>
            <div class="font-norm">
              {{(data.details.amount+ data.details.charge) | currency:'NGN'}}
            </div>
          </div>
        </div>

        <div>
          <p class="font-norm">
            Transaction Details
          </p>
          <div>
            <div class="flex-full-s m-b-lg">
              <p class="font-norm"><strong>
                  Trasaction type
                </strong></p>
              <div class="font-norm">
                {{data.details.type}}
              </div>
            </div>
            <div class="flex-full-s m-b-lg">
              <p class="font-norm"><strong>
                  Transaction Date
                </strong></p>
              <div class="font-norm">
                {{data.details.created_at | date}}
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>