import { Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-pin-input',
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss']
})
export class PinInputComponent {
  @Input() typer!:string;
  @Output() sendDataToParent: EventEmitter<{complete:boolean,type:string,value:string}> = new EventEmitter<any>();
  constructor(){
  //  console.log(this.type)
  }
  


  digits = [1, 2, 3, 4]; // Adjust the number of digits as needed
pin: any[] = [null,null,null,null];

handleInputChange(event: Event, index: number) {
  const target = event.target as HTMLInputElement;
  let value = target.value;
  //console.log(value)
  if (value.length > 1) {
    value = value.slice(0, 1); // Only take the first character
  }
  
  const elem=document.getElementById(`${this.typer}inputs-${index}`) as HTMLInputElement;
  elem.type="password";

  this.pin[index] = value;
  const letter= /^-?\d+\.?\d*$/.test(value);
  const el=event as KeyboardEvent;
  if(!letter && el.key!=="Backspace"){
    this.pin[index]=undefined;
    return
  }
  if(!this.pin.includes(null)){
    const value=this.pin.toString().split(',').join('');
   // console.log(value)
    //proceed to take input and call back
    this.sendDataToParent.emit({complete:true, type:this.typer,value})
  }
  else{
    this.sendDataToParent.emit({complete:false, type:this.typer,value:''})
  }
  const key=event as KeyboardEvent;
  this.handleKeyDown(key,index)

}

handleInputFocus(event:Event,index: number) {
  if (this.pin[index] === '') {
     //this.pin[index] = this.pin[index];
  }
}

handleInputBlur(index: number) {
  if (this.pin[index] === ' ') {
   // this.pin[index] = '';
  }
}

handleKeyDown(event: KeyboardEvent, index: number) {
 // this.handleInputChange(event,index)
  //console.log(this.pin[index])
  if (event.key === 'Backspace' && this.pin[index] === '') {
    if (index > 0) {
     // console.log(index)
      this.pin[index - 1] = null;
      const prevInput = document.getElementById(`${this.typer}inputs-${index - 1}`) as HTMLInputElement;
      const prev = document.getElementById(`${this.typer}inputs-${index}`) as HTMLInputElement;
      prev.type="tel";
      prevInput.focus();
    }
  } else if (event.key !== 'Backspace' && index < this.digits.length - 1 && this.pin[index] !== '') {
   // console.log(this.pin)
   // console.log('now here')
    const nextInput = document.getElementById(`${this.typer}inputs-${index + 1}`) as HTMLInputElement;
    //console.log(nextInput)
    nextInput.type="tel";
    nextInput.focus();
  }
}
}
