<div>
    <div class="m-b-lg">
        <h1 class="font-big align-center"><strong>{{data?.header}}</strong></h1>
    </div>
    <div class="m-b-lg">
        <div *ngFor="let item of data.body | keyvalue">
            <div class="flex-full-s">
                <div class="font-norm">{{item.key}}</div>
                <div class="font-norm"><strong>{{item.value}}</strong></div>
            </div>
            <p-divider></p-divider>
        </div>
    </div>
    <div *ngIf="data.showFooter">
        <div *ngFor="let item of data.footer | keyvalue">
            <div class="flex-full-s">
                <div class="font-norm">{{item.key}}</div>
                <div class="font-norm"><strong>{{item.value}}</strong></div>
            </div>
            <p-divider></p-divider>
        </div> 
    </div>
    <div>
        <div *ngIf="data.showButton" class="center-flex">
            <p-button label="{{data?.buttonLabel}}" [style]="{width:'100%', background:'#56006B'}" (click)="close()"></p-button>     
        </div>
    </div>
</div>