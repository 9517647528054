import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActionsService } from 'src/app/services/actions.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {
data:any={};
constructor(
  public action:ActionsService,
  public config:DynamicDialogConfig
){
//  console.log(this.config.data)
this.data=this.config.data;
}

public close=()=>{
  this.action.modalClose({success:true,data:{}})
}
}
