<div class="flex-n" *ngIf="!data.loading">
  <div>
    <div class="m-t" (click)="action.history()">
      <p-button label="Back" [outlined]="true" severity="secondary" [rounded]="true" icon="pi pi-angle-left"
        size="small"></p-button>
    </div>
    <div>
      <h1 class="font-bold font-big">Transfer funds</h1>
      <p class="font-light font-fade font-small">Send funds to beneficiaries and new accounts.</p>
    </div>
    <div style="max-width:1230px;">
      <div class="box-ben m-b-lg">
        <div class="flex-full m-b-lg">
          <div class="font-bold font-norm"><strong>Beneficiaries</strong></div>
          <div class="font-bold font-norm"><strong>View All</strong></div>
        </div>
        <div class="flex-full">
          <div *ngFor="let item of data.beneficiaries" [ngClass]="{'display': data.beneficiaries.length==0}">
            <img src="{{item.img}}" alt="">
            <div class="font-bold align-center font-norm">{{item.name}}</div>
          </div>
          <div [ngClass]="{'display': data.beneficiaries.length!=0}">
            <p class="font-light align-center font-mid font-fade">You have no saved beneficiaries</p>
          </div>
        </div>
      </div>
    </div>

    <div class="box-ben">
      <div class="m-b-lg">
        <h3 class="font-bold font-norm"><strong>Transfer funds</strong></h3>
        <div class="font-light font-mid font-fade">Enter bank details and, make sure that details are verified and
          confirmed.</div>
      </div>
      <div class="m-b-lg">
        <div class="font-normal font-bold mb-sm">Bank</div>
        <p-dropdown [options]="data.banks" [(ngModel)]="data.selectedBank" optionLabel="name" [filter]="true"
          filterBy="name" [showClear]="true" placeholder="Select bank" [style]="{width:'80%'}"
          [disabled]="data.checkAccount" (onChange)="fetchAccount()">
          <ng-template pTemplate="selectedItem">
            <div class="flex gap-2 align-items-center" *ngIf="data.selectedBank">
              <img src="{{data.selectedBank.avatar}}" style="width: 18px" />
              <div>{{ data.selectedBank.name }}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex gap-2 font-bold align-items-center font-norm">
              <img src="{{country.avatar}}" style="width: 18px" />
              <div>{{ country.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div>
        <div class="font-normal font-bold mb-sm">Account number</div>
        <div class="m-b-lg">
          <span class="p-input-icon-right">
            <i class="pi pi-spin pi-spinner" *ngIf="data.checkAccount"></i>
            <input type="text" autocomplete="off" pInputText [(ngModel)]="data.accountNo" [disabled]="data.checkAccount"
              class="w-f" placeholder="account number" (keyup)="getAccountInfo($event)" />
          </span>

        </div>

        <div class="m-b-lg">
          <div class="font-normal font-bold mb-sm">Amount</div>
          <p-inputNumber autocomplete="off" inputId="integeronly"
            pTooltip="{{data?.accountInfo?'':'Please enter recipient details before amount'}}" [(ngModel)]="data.amount"
            [disabled]="!data.accountInfo" mode="currency" currency="NGN" locale="en-GB" [style]="doWidth()">
          </p-inputNumber>
        </div>

        <div class="m-b-lg">
          <div class="font-normal font-bold mb-sm">Narration</div>
          <input type="text" pInputText [(ngModel)]="data.narration" [disabled]="!data.accountInfo" class="w-f" />
        </div>


        <div *ngIf="data.accountInfo" class="flex-full m-b-lg" style="width: 80%;">
          <div class="flex font-norm font-green">
            <i class="pi pi-check-circle" style="margin-right:5px; color: green;"></i>
            <div style="color: green;" class="font-bold font-norm">{{data.accountInfo.accountName}}</div>
          </div>
          <div class="flex-n">
            <div class="font-light font-norm" style="margin-right: 10px;">Save as beneficiary</div>
            <p-inputSwitch [(ngModel)]="data.checked" [style]="{marginTop:'-5px'}"></p-inputSwitch>
          </div>
        </div>
        <div class="center-flex">
          <p-button label="Continue" [disabled]="!data.accountInfo || !data.amount || !data.narration"
            [loading]="data.loading" [style]="{width:'29vw', background:'#56006B', 'border-radius':'20px'}"
            (click)="confirmDetails()"></p-button>
        </div>
      </div>
    </div>
  </div>
  <div class="d-auto">
    <img src="../../assets/skins/hand.png" alt="">
  </div>
</div>

<div *ngIf="data.loading">
  <div class="center-flex-n">
    <div class="loading"></div>
  </div>
</div>