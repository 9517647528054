import { Component, AfterContentInit } from '@angular/core';
import { ActionsService } from '../services/actions.service';
@Component({
  selector: 'app-bills-pay',
  templateUrl: './bills-pay.component.html',
  styleUrls: ['./bills-pay.component.scss']
})
export class BillsPayComponent {
  data:any={};
constructor(
  public action:ActionsService
){
}


async ngAfterContentInit(): Promise<void> {
  //Called after every check of the component's view. Applies to components only.
  //Add 'implements AfterViewChecked' to the class.
  if(this.action.getItem() && this.action.getItem().type){
    this.data['type']=this.action.getItem()?.type;
    try {
      this.data['loading']=true;
      const get=await this.action.Request(`billpayment/getBillers?type=${this.data.type}`,{},'GET');
      this.data.loading=false;
      if(!get.data.success){
        this.action.toast('error',get.data.message);
        return;
      }
    //  console.log(get.data.data)
      this.data['providers']=get.data.data;
    } catch (error:any) {
      this.data.loading=false;
      this.action.toast('error',error,'An error occured')
    }
  }
  else{
this.action.history()
  }
}

go=(item:{type:string,providers:Array<{}>})=>{
  item['type']=this.data.type;
  item['providers']=this.data['providers'];
  this.action.saveItem(item);
  this.action.navigate('billsMenu');
}

}
