import { Component } from '@angular/core';
import { ActionsService } from 'src/app/services/actions.service';
@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent {
data:any={};
constructor(
  public action:ActionsService
){
this.data['side']=false;
}

rem=()=>{
  this.action.data.defaultSide=true
}

checker=(url:string)=>{
  return this.action.data['current'].includes(url);

}
}
