import { Component } from '@angular/core';
import { ActionsService } from 'src/app/services/actions.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  data:any={};
  constructor(public action:ActionsService){
this.data['user']=this.action.secureGet('user');
//console.log(this.data.user)
  }

  defaultPhoto=()=>{
   this.data.user['profile_photo_url']="../../../assets/skins/Frame_8340.png";
  }

}
