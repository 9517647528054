<div>
    <div class="center-flex-c" >
        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 108 107" fill="none" *ngIf="data.type=='success'">
            <path d="M33.7412 82.6244L27.5735 104.225C27.2357 103.671 27.008 103.018 27.008 102.266V81.1252L76.6913 32.5691L33.7412 82.6244ZM30.868 106.031C31.7903 106.024 32.724 105.713 33.4869 104.95L47.295 91.1377L36.7738 85.8772L30.868 106.031ZM102.292 0.603284L7.27889 47.3527C-1.16993 51.5088 -1.20788 63.5443 7.21437 67.7536L25.7744 77.0336L97.0884 7.3631C97.8627 6.60779 99.0469 6.47495 99.9692 7.03668C101.207 7.79579 101.472 9.481 100.527 10.5855L38.6602 82.6092L72.2126 99.3095C78.8396 102.623 86.8177 98.8237 88.4194 91.5894L107.666 4.83149C108.365 1.72296 105.15 -0.801057 102.292 0.603284Z" fill="#039847"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 95 95" fill="none" *ngIf="data.type!='success'">
            <path d="M47.4806 8.44336C25.8323 8.44336 8.19141 26.0842 8.19141 47.7326C8.19141 69.3809 25.8323 87.0218 47.4806 87.0218C69.129 87.0218 86.7698 69.3809 86.7698 47.7326C86.7698 26.0842 69.129 8.44336 47.4806 8.44336ZM60.6818 56.7691C61.8212 57.9085 61.8212 59.7944 60.6818 60.9338C60.0925 61.5231 59.346 61.7981 58.5995 61.7981C57.853 61.7981 57.1065 61.5231 56.5171 60.9338L47.4806 51.8972L38.4441 60.9338C37.8548 61.5231 37.1083 61.7981 36.3618 61.7981C35.6153 61.7981 34.8688 61.5231 34.2794 60.9338C33.1401 59.7944 33.1401 57.9085 34.2794 56.7691L43.316 47.7326L34.2794 38.6961C33.1401 37.5567 33.1401 35.6708 34.2794 34.5314C35.4188 33.392 37.3047 33.392 38.4441 34.5314L47.4806 43.5679L56.5171 34.5314C57.6565 33.392 59.5424 33.392 60.6818 34.5314C61.8212 35.6708 61.8212 37.5567 60.6818 38.6961L51.6453 47.7326L60.6818 56.7691Z" fill="#EE2011"/>
          </svg>
    </div>
    <div>
        <h1 class="align-center font-big" [ngStyle]="{color:data.type=='success'?'green':'red'}">{{this.data.title}}</h1>
        <div class="align-center">{{this.data.message}}</div>
    </div>
    <div *ngIf="data.showButton" class="center-flex">
        <p-button label="{{data?.buttonLabel}}" [style]="{width:'100%', background:'#56006B'}" (click)="close()"></p-button>     
    </div>
</div>