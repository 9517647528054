<div *ngIf="action.getItem().type=='setup'" class="p-g">
  <div class="circle-sm">
    <img src="../../../assets/skins/lock.svg" alt="">
  </div>
  <div>
    <h1 class="font-bold sp-font"><strong>Set wallet pin</strong></h1>
    <p class="font-light font-small">Set up a secret wallet pin, this should be known
      only by you.</p>
  </div>


  <div class="m-b-lg">
    <h1 class="font-bold sp-font"><strong>Enter OTP</strong></h1>
    <p class="font-light font-small">An OTP was sent to your email address, do enter your OTP
      to set your wallet pin</p>
    <app-pin-input typer="otp" (sendDataToParent)="getInput($event)"></app-pin-input>
  </div>
  <div class="m-b-lg">
    <h1 class="font-bold sp-font"><strong>Create Wallet pin</strong></h1>
    <p class="font-light font-small">Create a Four (4) digit unique pin to access your wallet.</p>
    <app-pin-input typer="pin" (sendDataToParent)="getInput($event)"></app-pin-input>
  </div>
  <div class="m-b-lg">
    <h1 class="font-bold sp-font"><strong>Confirm Pin</strong></h1>
    <app-pin-input typer="pin-confirm" (sendDataToParent)="getInput($event)"></app-pin-input>
  </div>
  <p-divider></p-divider>
  <div>
    <p-button label="Save wallet pin" [disabled]="data.disable" [loading]="data.loading"
      [style]="{width:'100%', background:'#56006B', 'border-radius':'10px'}" (click)="proceed()"></p-button>
  </div>
</div>
<div *ngIf="action.getItem().type=='login'" class="p-g">
  <div class="circle-sm">
    <img src="../../../assets/skins/lock.svg" alt="">
  </div>
  <div>
    <h1 class="font-bold sp-font"><strong>Enter your wallet pin</strong></h1>
    <p class="font-light font-small">Kindly enter your four (4) digit pin to proceed.</p>
  </div>
  <div class="m-b-lg">
    <h1 class="font-bold font-mid"><strong>Enter pin</strong></h1>
    <div>
      <app-pin-input typer="pin" (sendDataToParent)="getInput2($event)"></app-pin-input>
    </div>
  </div>
  <!-- <p-divider></p-divider> -->
  <div>
    <p-button label="Proceed" [disabled]="data.disable" [loading]="data.loading"
      [style]="{width:'100%', background:'#56006B', 'border-radius':'10px'}" (click)="proceedPin()"></p-button>
  </div>
</div>

<div *ngIf="action.getItem().type=='transaction'" class="p-g">
  <div class="circle-sm">
    <img src="../../../assets/skins/lock.svg" alt="">
  </div>
  <div>
    <h1 class="font-bold sp-font"><strong>Set transaction pin</strong></h1>
    <p class="font-light font-small">Set up a secret transaction pin, this should be known
      only by you.</p>
  </div>
  <div>
    <h1 class="font-bold font-big"><strong>Create transaction pin</strong></h1>
    <p class="font-light font-small">Create a Four (4) digit unique pin to make transactions on your wallet</p>
  </div>
  <div>
    <div>
      <app-pin-input typer="pin" (sendDataToParent)="getInput($event)"></app-pin-input>
    </div>
  </div>
  <div class="m-b-lg">
    <h1 class="font-bold font-big"><strong>Confirm Pin</strong></h1>
    <app-pin-input typer="pin-confirm" (sendDataToParent)="getInput($event)"></app-pin-input>
  </div>
  <div>
    <p-button label="Save transaction pin" [disabled]="data.disable" [loading]="data.loading"
      [style]="{width:'100%', background:'#56006B', 'border-radius':'10px'}"
      (click)="proceedTransactionPin()"></p-button>
  </div>
</div>