<p-sidebar [(visible)]="data.side" position="right" [style]="doWidth()">
  <div>
    <div class="center-flex-c">
      <svg xmlns="http://www.w3.org/2000/svg" width="202" height="200" viewBox="0 0 202 200" fill="none">
        <path
          d="M183.503 200H87.9027C86.7019 200.006 85.5125 199.767 84.4065 199.299C83.3006 198.832 82.3012 198.144 81.4691 197.278C80.6369 196.412 79.9893 195.387 79.5656 194.263C79.1418 193.14 78.9507 191.942 79.0038 190.742L86.0244 9.25796C86.1435 6.78059 87.2046 4.44232 88.9907 2.72142C90.7767 1.00052 93.1528 0.0270452 95.6329 9.98872e-05H191.233C192.434 -0.00560011 193.624 0.232706 194.73 0.700559C195.836 1.16841 196.835 1.85606 197.667 2.72178C198.499 3.58751 199.147 4.61326 199.571 5.73682C199.994 6.86038 200.185 8.05831 200.132 9.25796L193.112 190.742C192.993 193.219 191.932 195.558 190.145 197.279C188.359 198.999 185.983 199.973 183.503 200Z"
          fill="#407BFF" />
        <path d="M200.069 70.4803L199.485 85.4785L200.403 85.5142L200.987 70.516L200.069 70.4803Z" fill="#407BFF" />
        <path d="M200.547 58.812L200.305 65.0098L201.222 65.0457L201.465 58.8479L200.547 58.812Z" fill="#407BFF" />
        <path opacity="0.8"
          d="M183.503 200H87.9027C86.7019 200.006 85.5125 199.767 84.4065 199.299C83.3006 198.832 82.3012 198.144 81.4691 197.278C80.6369 196.412 79.9893 195.387 79.5656 194.263C79.1418 193.14 78.9507 191.942 79.0038 190.742L86.0244 9.25796C86.1435 6.78059 87.2046 4.44232 88.9907 2.72142C90.7767 1.00052 93.1528 0.0270452 95.6329 9.98872e-05H191.233C192.434 -0.00560011 193.624 0.232706 194.73 0.700559C195.836 1.16841 196.835 1.85606 197.667 2.72178C198.499 3.58751 199.147 4.61326 199.571 5.73682C199.994 6.86038 200.185 8.05831 200.132 9.25796L193.112 190.742C192.993 193.219 191.932 195.558 190.145 197.279C188.359 198.999 185.983 199.973 183.503 200Z"
          fill="white" />
        <path
          d="M174.353 196.317H90.0391C88.9865 196.322 87.944 196.113 86.9747 195.702C86.0054 195.292 85.1294 194.689 84.3997 193.931C83.67 193.172 83.1018 192.274 82.7295 191.289C82.3572 190.305 82.1886 189.255 82.2337 188.203L88.8036 18.2393C88.9099 16.0669 89.8418 14.0171 91.409 12.5089C92.9761 11.0007 95.0601 10.148 97.235 10.1251H181.549C182.602 10.1196 183.645 10.328 184.615 10.7378C185.585 11.1476 186.461 11.7502 187.191 12.509C187.921 13.2678 188.489 14.167 188.861 15.152C189.233 16.1371 189.401 17.1874 189.354 18.2393L182.785 188.203C182.676 190.375 181.744 192.424 180.177 193.931C178.61 195.439 176.527 196.292 174.353 196.317Z"
          fill="#407BFF" />
        <path opacity="0.8"
          d="M136.521 186.694C138.752 184.505 138.855 180.991 136.75 178.845C134.645 176.699 131.129 176.734 128.898 178.923C126.666 181.112 126.564 184.626 128.669 186.772C130.774 188.918 134.289 188.883 136.521 186.694Z"
          fill="white" />
        <path
          d="M134.209 144.145C129.757 144.175 125.345 143.298 121.243 141.567C117.14 139.836 113.434 137.287 110.348 134.077C107.263 130.867 104.864 127.062 103.297 122.894C101.73 118.726 101.029 114.283 101.235 109.835L102.362 80.5757H102.304C101.974 80.5742 101.644 80.5462 101.319 80.4922L100.183 109.835C99.9723 114.418 100.697 118.997 102.312 123.291C103.928 127.585 106.401 131.505 109.58 134.813C112.76 138.121 116.58 140.746 120.807 142.53C125.034 144.314 129.58 145.218 134.168 145.189C143.891 145.145 153.2 141.242 160.046 134.336C159.797 134.084 159.579 133.804 159.395 133.502C156.106 136.849 152.188 139.513 147.865 141.339C143.543 143.166 138.902 144.12 134.209 144.145Z"
          fill="white" />
        <path
          d="M138.249 39.5359C120.36 39.5359 105.025 52.8926 102.036 70.1645C102.259 70.1515 102.482 70.1515 102.704 70.1645C102.838 70.1645 102.963 70.1645 103.096 70.1645C106.052 53.4686 120.902 40.5961 138.208 40.5961C142.661 40.5653 147.074 41.4418 151.177 43.1722C155.28 44.9027 158.988 47.4508 162.074 50.6613C165.16 53.8717 167.56 57.6771 169.128 61.8454C170.695 66.0138 171.397 70.4576 171.19 74.9061L169.838 109.851C169.619 115.308 168.13 120.639 165.489 125.419C165.82 125.516 166.138 125.656 166.432 125.837C169.137 120.926 170.66 115.453 170.882 109.851L172.234 74.9061C172.448 70.3213 171.726 65.7408 170.112 61.4441C168.498 57.1474 166.026 53.2246 162.846 49.9148C159.666 46.6051 155.845 43.9779 151.616 42.1935C147.387 40.4091 142.839 39.5048 138.249 39.5359Z"
          fill="white" />
        <path
          d="M137.974 46.6882C143.599 46.6745 149.108 48.2894 153.835 51.3381C153.985 51.0145 154.167 50.7069 154.378 50.4198C149.488 47.2806 143.794 45.6224 137.983 45.6448C131.407 45.6659 124.996 47.7057 119.617 51.4883C119.802 51.7835 119.955 52.0968 120.076 52.4233C125.306 48.7078 131.559 46.7043 137.974 46.6882Z"
          fill="white" />
        <path
          d="M105.667 113.187C105.408 111.399 105.313 109.591 105.383 107.786L106.577 76.8987C106.837 70.5316 109.046 64.3978 112.905 59.3263C112.606 59.1756 112.326 58.9907 112.07 58.7754C108.097 64.0082 105.824 70.3338 105.559 76.8987L104.365 107.786C104.292 109.675 104.392 111.567 104.665 113.438C104.99 113.322 105.326 113.238 105.667 113.187Z"
          fill="white" />
        <path
          d="M161.907 57.3438C161.654 57.6013 161.375 57.8308 161.072 58.0283C165.21 63.4387 167.314 70.1316 167.016 76.9363L165.822 107.824C165.441 115.919 161.975 123.562 156.137 129.183C150.299 134.804 142.53 137.977 134.426 138.052C127.992 138.081 121.73 135.976 116.62 132.066C116.321 132.298 115.996 132.494 115.651 132.65C120.988 136.854 127.591 139.126 134.384 139.095C142.768 139.019 150.806 135.736 156.846 129.921C162.886 124.106 166.472 116.199 166.866 107.824L168.06 76.9363C168.373 69.8857 166.195 62.9496 161.907 57.3438Z"
          fill="white" />
        <path
          d="M110.184 89.1801L110.576 78.979C110.906 71.9769 113.904 65.3667 118.954 60.5048C124.003 55.6429 130.722 52.898 137.732 52.8332C143.633 52.7897 149.358 54.8389 153.891 58.617C158.424 62.395 161.471 67.6573 162.492 73.4693C162.834 73.4087 163.18 73.378 163.527 73.3775C162.489 67.3061 159.327 61.8013 154.605 57.8467C149.882 53.8921 143.908 51.7453 137.749 51.7897C130.458 51.8543 123.469 54.7078 118.217 59.7643C112.965 64.8208 109.849 71.6964 109.508 78.979L109.132 89.2553C109.373 89.2255 109.615 89.2088 109.858 89.2052L110.184 89.1801Z"
          fill="white" />
        <path
          d="M145.562 129.541C142.15 131.079 138.452 131.879 134.709 131.887C131.31 131.919 127.941 131.257 124.806 129.942C121.671 128.627 118.838 126.687 116.479 124.24C114.12 121.792 112.285 118.89 111.086 115.709C109.887 112.528 109.35 109.137 109.507 105.741L109.749 99.597H109.423C109.183 99.5978 108.943 99.5811 108.705 99.5469L108.463 105.724C108.3 109.249 108.856 112.771 110.099 116.074C111.341 119.377 113.243 122.393 115.688 124.937C118.134 127.481 121.072 129.5 124.324 130.872C127.575 132.243 131.072 132.938 134.601 132.914C138.562 132.909 142.476 132.055 146.079 130.409C145.874 130.141 145.7 129.849 145.562 129.541Z"
          fill="white" />
        <path
          d="M163.126 83.8271C162.976 83.8271 162.826 83.8271 162.684 83.8271L161.849 105.765C161.563 112.433 158.829 118.761 154.169 123.538C154.378 123.824 154.554 124.132 154.695 124.456C159.667 119.47 162.592 112.801 162.893 105.765L163.727 83.8187C163.535 83.8104 163.335 83.8271 163.126 83.8271Z"
          fill="white" />
        <path
          d="M146.857 59.9132C143.929 58.5919 140.753 57.9116 137.541 57.918C131.344 57.973 125.404 60.3979 120.939 64.695C116.474 68.9921 113.824 74.8353 113.532 81.0251L112.655 103.69C112.608 105.026 112.675 106.364 112.856 107.688C113.16 107.411 113.493 107.168 113.849 106.962C113.715 105.877 113.664 104.783 113.699 103.69L114.575 81.0251C114.855 75.1151 117.385 69.5361 121.648 65.4332C125.911 61.3303 131.582 59.0146 137.499 58.9615C140.537 58.9657 143.539 59.6174 146.306 60.8732C146.458 60.5363 146.643 60.2148 146.857 59.9132Z"
          fill="white" />
        <path
          d="M154.888 66.1504C154.685 66.4508 154.453 66.7305 154.195 66.9852C157.334 70.9732 158.937 75.956 158.711 81.0264L157.834 103.691C157.56 109.43 155.162 114.863 151.106 118.933C147.05 123.002 141.626 125.419 135.888 125.713C135.779 126.086 135.63 126.447 135.445 126.79C141.543 126.6 147.344 124.116 151.689 119.833C156.034 115.55 158.601 109.785 158.878 103.691L159.754 81.0264C159.994 75.6411 158.264 70.3529 154.888 66.1504Z"
          fill="white" />
        <path
          d="M133.123 65.5735C133.14 65.2079 133.19 64.8446 133.273 64.4883C130.607 65.0666 128.089 66.1892 125.877 67.7857C126.199 67.9497 126.497 68.1576 126.762 68.4035C128.685 67.0682 130.843 66.108 133.123 65.5735Z"
          fill="white" />
        <path
          d="M153.017 106.313C151.843 110.147 149.479 113.508 146.267 115.909C143.055 118.31 139.163 119.627 135.153 119.669C132.815 119.686 130.498 119.225 128.344 118.316C126.19 117.406 124.244 116.067 122.625 114.38C121.007 112.694 119.749 110.694 118.929 108.505C118.109 106.315 117.744 103.981 117.856 101.646L118.574 83.072C118.663 80.8768 119.15 78.7159 120.01 76.6942C119.712 76.4527 119.448 76.1719 119.225 75.8594C118.215 78.1266 117.648 80.5665 117.555 83.0469L116.838 101.621C116.724 104.089 117.114 106.554 117.984 108.866C118.854 111.178 120.186 113.288 121.898 115.069C123.61 116.85 125.667 118.263 127.942 119.223C130.218 120.184 132.666 120.671 135.136 120.654C139.383 120.606 143.505 119.21 146.907 116.666C150.308 114.123 152.814 110.565 154.061 106.505C153.708 106.472 153.359 106.408 153.017 106.313Z"
          fill="white" />
        <path
          d="M154.585 83.0681L154.435 86.9249C154.711 86.8756 154.99 86.8477 155.27 86.8414H155.495L155.637 83.0431C155.802 79.1 154.677 75.2107 152.435 71.9634C150.192 68.7161 146.953 66.2875 143.207 65.0449C143.24 65.4171 143.24 65.7914 143.207 66.1635C146.661 67.395 149.63 69.7011 151.678 72.7434C153.726 75.7858 154.745 79.4043 154.585 83.0681Z"
          fill="white" />
        <path
          d="M137.056 70.1646C133.047 70.2029 129.204 71.7741 126.317 74.5559C123.429 77.3377 121.716 81.1191 121.529 85.1241L121.37 89.248C121.719 89.271 122.065 89.324 122.405 89.4066L122.572 85.1241C122.747 81.3926 124.347 77.8703 127.042 75.2829C129.736 72.6955 133.32 71.2401 137.056 71.2164C138.86 71.2066 140.647 71.5638 142.308 72.2664C143.969 72.9689 145.47 74.0021 146.72 75.3031C147.969 76.6041 148.941 78.1456 149.576 79.834C150.211 81.5223 150.495 83.3222 150.413 85.1241L149.853 99.5994C149.696 102.858 148.454 105.971 146.324 108.443C144.194 110.915 141.299 112.603 138.099 113.24C138.137 113.59 138.137 113.942 138.099 114.292C141.575 113.64 144.73 111.836 147.054 109.17C149.378 106.504 150.735 103.132 150.905 99.5994L151.464 85.1241C151.554 83.1823 151.247 81.2425 150.562 79.4234C149.877 77.6043 148.828 75.944 147.479 74.5439C146.131 73.1438 144.511 72.0334 142.719 71.2804C140.927 70.5274 139 70.1478 137.056 70.1646Z"
          fill="white" />
        <path
          d="M127.306 82.4333C127.523 82.4204 127.74 82.4204 127.957 82.4333C128.856 80.8845 130.144 79.5969 131.693 78.698C133.242 77.7991 134.998 77.3199 136.789 77.3077C137.881 77.3079 138.965 77.4942 139.995 77.8587C140.112 77.5254 140.26 77.2039 140.437 76.8987C139.282 76.4754 138.061 76.2579 136.831 76.2559C134.758 76.2775 132.731 76.8631 130.966 77.95C129.201 79.0369 127.766 80.5839 126.813 82.425C126.978 82.4195 127.142 82.4223 127.306 82.4333Z"
          fill="white" />
        <path
          d="M145.837 97.55C145.783 98.7448 145.517 99.9206 145.053 101.023C145.378 101.143 145.684 101.309 145.963 101.515C146.509 100.261 146.821 98.9169 146.881 97.55L147.282 87.1652C147.313 86.1964 147.215 85.2278 146.989 84.2852C146.675 84.4516 146.341 84.5805 145.996 84.6692C146.188 85.4894 146.269 86.3317 146.238 87.1735L145.837 97.55Z"
          fill="white" />
        <path
          d="M137.891 107.102C137.142 107.283 136.374 107.375 135.604 107.378C134.332 107.38 133.074 107.124 131.904 106.626C130.734 106.128 129.678 105.397 128.799 104.478C127.92 103.56 127.237 102.472 126.791 101.281C126.345 100.091 126.145 98.8221 126.204 97.552L126.388 92.8522C126.037 92.8218 125.691 92.7575 125.353 92.6602L125.161 97.552C125.098 98.96 125.323 100.366 125.821 101.684C126.319 103.003 127.08 104.206 128.058 105.221C129.035 106.236 130.209 107.042 131.508 107.589C132.806 108.136 134.203 108.414 135.612 108.404C136.487 108.401 137.358 108.295 138.208 108.087C138.074 107.769 137.968 107.439 137.891 107.102Z"
          fill="white" />
        <path
          d="M136.588 82.4201C134.762 82.4419 133.014 83.163 131.703 84.4348C130.393 85.7066 129.619 87.4324 129.543 89.257L129.301 95.5514C129.278 96.1382 129.337 96.7254 129.476 97.2961C129.741 96.9761 130.05 96.6951 130.394 96.4613C130.352 96.1627 130.336 95.8611 130.344 95.5597L130.586 89.2654C130.661 87.7259 131.321 86.2732 132.433 85.2053C133.544 84.1374 135.022 83.5351 136.563 83.522C137.306 83.5201 138.042 83.669 138.725 83.9596C139.409 84.2501 140.027 84.6763 140.541 85.2124C141.055 85.7485 141.455 86.3834 141.717 87.0786C141.979 87.7738 142.097 88.5149 142.065 89.257L141.822 95.5514C141.768 96.6676 141.406 97.7469 140.775 98.6695C140.144 99.592 139.27 100.322 138.25 100.777C138.231 101.171 138.176 101.563 138.083 101.946C139.428 101.48 140.602 100.622 141.455 99.4823C142.308 98.3424 142.799 96.9732 142.866 95.5514L143.116 89.257C143.163 88.373 143.029 87.4887 142.721 86.6585C142.413 85.8283 141.939 85.0698 141.328 84.4295C140.717 83.7892 139.981 83.2807 139.166 82.9351C138.351 82.5895 137.474 82.4143 136.588 82.4201Z"
          fill="white" />
        <path
          d="M154.144 7.36263H124.843C124.626 7.36273 124.411 7.31924 124.211 7.23474C124.012 7.15024 123.831 7.02646 123.68 6.87073C123.529 6.715 123.41 6.5305 123.332 6.3282C123.254 6.12589 123.217 5.9099 123.223 5.69305C123.249 5.24413 123.444 4.82172 123.77 4.51162C124.095 4.20152 124.527 4.02698 124.977 4.02346H154.269C154.487 4.02224 154.703 4.0649 154.904 4.1489C155.104 4.2329 155.286 4.35652 155.438 4.51236C155.59 4.66821 155.709 4.85309 155.788 5.05595C155.867 5.25881 155.904 5.47549 155.897 5.69305C155.87 6.14128 155.674 6.56256 155.349 6.87226C155.024 7.18196 154.593 7.35714 154.144 7.36263Z"
          fill="#407BFF" />
        <path opacity="0.2"
          d="M108.314 36.6635C106.581 36.7993 104.842 36.8383 103.105 36.7804C102.491 36.6488 101.86 36.6094 101.235 36.6635H101.043C96.1419 36.3045 91.2926 35.4286 86.5758 34.0506C86.0916 33.9087 85.5907 33.7417 85.0815 33.5664L81.0244 138.642L108.138 120.552C109.364 119.747 110.381 118.663 111.107 117.389C111.832 116.115 112.246 114.687 112.312 113.222L115.034 42.9745C115.073 42.0999 114.922 41.2273 114.592 40.4164C114.262 39.6055 113.761 38.8754 113.123 38.2761C112.485 37.6768 111.725 37.2224 110.895 36.9441C110.065 36.6658 109.184 36.57 108.314 36.6635Z"
          fill="black" />
        <path
          d="M101.778 28.8239C100.048 28.9626 98.3115 29.0044 96.577 28.9491C95.9628 28.8189 95.3331 28.7768 94.707 28.8239H94.5067C89.6081 28.4683 84.7613 27.5923 80.0481 26.211C74.138 24.3736 68.6918 21.2876 64.0785 17.1619C62.9579 16.0826 61.4602 15.4836 59.9045 15.4923H52.8255C51.2388 15.5038 49.7118 16.098 48.5346 17.1619C43.6378 21.2752 37.9582 24.3535 31.8388 26.211C27.0559 27.5862 22.1433 28.4619 17.1798 28.8239H16.9794C16.3506 28.7768 15.7182 28.8189 15.1011 28.9491C13.3694 29.0045 11.6358 28.9627 9.90874 28.8239C9.01931 28.7559 8.12529 28.8668 7.27942 29.15C6.43355 29.4332 5.65299 29.883 4.9838 30.4728C4.31462 31.0626 3.77037 31.7805 3.38319 32.5841C2.99601 33.3877 2.77374 34.2607 2.72951 35.1517L0.00808583 105.408C-0.0539086 106.83 0.241365 108.245 0.866941 109.524C1.49252 110.803 2.42846 111.905 3.58934 112.729L42.9499 140.411C44.3998 141.428 46.1292 141.97 47.9002 141.963H54.9709C56.7787 141.957 58.5443 141.417 60.0464 140.411L101.544 112.729C102.766 111.923 103.781 110.839 104.506 109.567C105.231 108.295 105.646 106.87 105.718 105.408L108.431 35.1517C108.467 34.283 108.316 33.4167 107.99 32.6109C107.664 31.805 107.169 31.0782 106.539 30.479C105.909 29.8799 105.159 29.4223 104.337 29.1368C103.516 28.8514 102.644 28.7447 101.778 28.8239Z"
          fill="#407BFF" />
        <path opacity="0.8"
          d="M101.778 28.8239C100.048 28.9626 98.3115 29.0044 96.577 28.9491C95.9628 28.8189 95.3331 28.7768 94.707 28.8239H94.5067C89.6081 28.4683 84.7613 27.5923 80.0481 26.211C74.138 24.3736 68.6918 21.2876 64.0785 17.1619C62.9579 16.0826 61.4602 15.4836 59.9045 15.4923H52.8255C51.2388 15.5038 49.7118 16.098 48.5346 17.1619C43.6378 21.2752 37.9582 24.3535 31.8388 26.211C27.0559 27.5862 22.1433 28.4619 17.1798 28.8239H16.9794C16.3506 28.7768 15.7182 28.8189 15.1011 28.9491C13.3694 29.0045 11.6358 28.9627 9.90874 28.8239C9.01931 28.7559 8.12529 28.8668 7.27942 29.15C6.43355 29.4332 5.65299 29.883 4.9838 30.4728C4.31462 31.0626 3.77037 31.7805 3.38319 32.5841C2.99601 33.3877 2.77374 34.2607 2.72951 35.1517L0.00808583 105.408C-0.0539086 106.83 0.241365 108.245 0.866941 109.524C1.49252 110.803 2.42846 111.905 3.58934 112.729L42.9499 140.411C44.3998 141.428 46.1292 141.97 47.9002 141.963H54.9709C56.7787 141.957 58.5443 141.417 60.0464 140.411L101.544 112.729C102.766 111.923 103.781 110.839 104.506 109.567C105.231 108.295 105.646 106.87 105.718 105.408L108.431 35.1517C108.467 34.283 108.316 33.4167 107.99 32.6109C107.664 31.805 107.169 31.0782 106.539 30.479C105.909 29.8799 105.159 29.4223 104.337 29.1368C103.516 28.8514 102.644 28.7447 101.778 28.8239Z"
          fill="white" />
        <path
          d="M4.60773 105.279L7.20394 38.1032C7.26148 36.8716 7.78833 35.7087 8.67632 34.8533C9.5643 33.998 10.746 33.515 11.979 33.5035H13.5066C20.1123 33.4214 26.6759 32.4384 33.0157 30.5817C39.046 28.7541 44.7005 25.8619 49.7116 22.0418C50.5368 21.3692 51.5688 21.002 52.6334 21.002C53.698 21.002 54.7299 21.3692 55.5552 22.0418C60.3109 25.886 65.7565 28.7874 71.5999 30.5901C77.8635 32.4562 84.3567 33.4396 90.892 33.5119H92.3028C92.9108 33.5066 93.5135 33.6255 94.0739 33.8614C94.6344 34.0973 95.1407 34.4451 95.562 34.8836C95.9833 35.322 96.3106 35.8419 96.5239 36.4113C96.7371 36.9807 96.8319 37.5877 96.8023 38.195L94.2061 105.287C94.1713 106.043 93.9589 106.78 93.5862 107.439C93.2134 108.097 92.6908 108.659 92.0607 109.077L50.8219 136.626C50.0567 137.147 49.1519 137.426 48.2257 137.426C47.2994 137.426 46.3947 137.147 45.6295 136.626L6.51106 109.077C5.89947 108.656 5.40431 108.087 5.07156 107.423C4.73881 106.759 4.57926 106.021 4.60773 105.279Z"
          fill="#407BFF" />
        <path opacity="0.2"
          d="M16.0684 101.72L18.2722 44.6119C24.1738 44.145 30.0135 43.085 35.7027 41.448C41.5064 39.7025 47.0386 37.157 52.1398 33.8848C57.026 37.1722 62.3706 39.7207 68.0008 41.448C73.606 43.0922 79.366 44.1524 85.1892 44.6119L82.977 101.72L48.6336 124.627L16.0684 101.72Z"
          fill="white" />
        <path opacity="0.2"
          d="M27.6143 95.7578L29.2087 54.5107C32.2641 53.9346 35.3444 53.2084 38.3914 52.3318C42.9578 50.9866 47.3833 49.203 51.6062 47.0059C55.6892 49.2105 59.9853 50.9949 64.4286 52.3318C67.445 53.2056 70.4586 53.9319 73.4694 54.5107L71.875 95.7578L49.1686 110.959L27.6143 95.7578Z"
          fill="white" />
      </svg>
    </div>
    <div class="m-bb">
      <h3 class="font-bold font-norm align-center"><strong>Confirm transaction pin</strong></h3>
    </div>
    <div>
      <p class="font-light align-center">Enter your 4-Digit PIN to confirm transaction</p>
      <div class="center-flex-c">
        <div class="m-b-lg" style="width: 70%;">
          <app-pin-input typer="transactionPin" (sendDataToParent)="getInput($event)"></app-pin-input>
        </div>
      </div>
      <div class="center-flex">
        <p-button label="Proceed" [disabled]="data.disable" [loading]="data.loading"
          [style]="{width:'100%', 'background':'#56006B'}" (click)="proceedPay()"></p-button>
      </div>
    </div>
  </div>
</p-sidebar>
<div class="scroll-container" #scrollContainer id="focusBtn">
  <div *ngIf="!data.loading" class="flex-n">
    <div>
      <div class="m-t" (click)="action.history()">
        <p-button label="Back" [outlined]="true" severity="secondary" [rounded]="true" icon="pi pi-angle-left"
          size="small"></p-button>
      </div>
      <div class="m-b-lg">
        <h1 class="font-bold font-big">{{data?.item?.shortName | uppercase}}</h1>
        <p class="font-light font-fade font-small">{{data?.item?.categoryServiceType}}</p>
      </div>
      <div *ngIf="data?.item?.type=='cable'">
        <div class="box-special">
          <div class="box-inner m-b-lg">
            <div class="m-b-lg">
              <h1 class="font-bold font-norm"><strong>Payment data</strong></h1>
              <p class="font-light font-norm font-fade">Type in your Smart card details.</p>
            </div>
            <div>
              <span class="p-float-label">
                <input type="number" [disabled]="data.busy" pInputText id="cardNo" [(ngModel)]="data.cardNo"
                  [style]="{width:'80%'}" (input)="checkUser($event)" maxlength="10" />
                <label htmlFor="cardNo">Smart card no</label>
              </span>
            </div>
            <div>
              <i class="pi pi-spin pi-spinner" *ngIf="data.busy"></i>

            </div>
          </div>
          <div class="box-inner" *ngIf="data.user">
            <h1 class="font-bold font-big"><strong>Customer information</strong></h1>
            <div class="flex-full">
              <h1 class="font-bold font-norm">Customer name: </h1>
              <h3>{{data.user.customerName}}</h3>
            </div>
            <p-divider *ngIf="data?.user?.currentPlan"></p-divider>
            <div *ngIf="data?.user?.currentPlan">
              <h1 class="font-bold font-norm">Current plans: </h1>
              <div *ngFor="let item of data.user.currentPlan.planInfos" class="flex-full">
                <p class="font-bold font-norm">{{item.planName}}</p>
                <p class="font-bold font-norm">{{item.priceAmount | currency:'NGN'}}</p>
              </div>
            </div>
            <p-divider *ngIf="data?.user?.currentPlan"></p-divider>
            <div class="flex-full" *ngIf="data.user.outStandingBalance">
              <h1 class="font-bold font-norm">Outstanding balance: </h1>
              <h3 class="font-bold font-norm">{{data.user.outStandingBalance | currency:'NGN'}}</h3>
            </div>
          </div>
          <div *ngIf="data.user && data.cardNo.toString().length=='10'">
            <h1 class="font-bold font-big"><strong>Bouquet</strong></h1>
            <div class="font-light font-small">Click on any of the listed Bouquet to proceed</div>
            <div class="flex-grow">
              <div *ngFor="let item of data.info let i=index;" class="box-small child cursor" (click)="select(item,i)"
                id="{{i}}">
                <div class="font-bold font-norm"><strong>{{item.name}}</strong></div>
                <h3 class="font-bold font-norm"><strong>{{item.price | currency:'NGN'}}</strong></h3>
              </div>
            </div>
          </div>
        </div>
        <div class="box-purple flex-full" *ngIf="data.selected && data.user && data.cardNo.toString().length=='10'">
          <div>
            <h3 class="font-bold font-norm">Your choice</h3>
            <h1 class="font-bold font-norm">{{data.selected.name}}</h1>
            <h1 class="font-bold font-big"><strong>{{data.selected.price | currency:'NGN'}}</strong></h1>
          </div>
          <div style="margin: auto; margin-right: 0px;">
            <p-button label="Proceed" [rounded]="true" severity="secondary"
              [style]="{background:'white', color:'black'}" (click)="data.side=true"></p-button>
          </div>
        </div>
      </div>

      <!-- electricity -->
      <div *ngIf="data?.item?.type=='power'" class="box-special">
        <div class="m-b-lg">
          <h1 class="font-bold font-norm"><strong>Payment data</strong></h1>
          <p class="font-bold font-mid">Type in your meter number</p>
        </div>
        <div>
          <div class="m-b-lg">
            <div class="font-light font-normal mb-sm">Payment item</div>
            <p-dropdown [options]="data?.item?.providers" [(ngModel)]="data.selectedItem"
              placeholder="Select a provider" optionLabel="name" inputId="float-label" [style]="doWidth()"
              (onChange)="checkUser(data.meterNo)"></p-dropdown>

          </div>
          <div class="font-normal font-bold mb-sm">Meter Number</div>
          <div class="m-b-lg">
            <span class="p-input-icon-right">
              <i class="pi pi-spin pi-spinner" *ngIf="data.busy"></i>
              <input type="text" autocomplete="off" pInputText [(ngModel)]="data.meterNo" [disabled]="data.busy"
                class="w-f" placeholder="Meter number" (keyup)="checkUser($event)" />
            </span>
            <div class="flex" *ngIf="data.user">
              <i class="pi pi-check-circle" style="margin-right:5px; color: #56006B;"></i>
              <div style="color: #56006B;" class="font-bold font-norm">{{data.user.customerName}}</div>
            </div>
          </div>

          <div class="m-b-lg">
            <div class="font-normal font-bold mb-sm">Amount</div>
            <p-inputNumber autocomplete="off" inputId="integeronly"
              pTooltip="{{data?.user?'':'Please enter recipient details before amount'}}" [(ngModel)]="data.amount"
              [disabled]="!data.user" mode="currency" currency="NGN" locale="en-GB" [style]="doWidth()">
            </p-inputNumber>
          </div>

          <div class="m-b-lg">
            <div class="font-normal font-bold mb-sm">Notification email</div>
            <input type="text" autocomplete="off" pInputText [(ngModel)]="data.email" [disabled]="!data.amount"
              class="w-f" placeholder="Notification email" />
          </div>

          <div class="m-b-lg">
            <div class="font-normal font-bold mb-sm">Notification phone number</div>
            <input type="number" autocomplete="off" pInputText [(ngModel)]="data.phone" [disabled]="!data.amount"
              class="w-f" placeholder="Notification phone" />
          </div>
          <div class="center-flex">
            <p-button label="Continue" [disabled]="!data.user || !data.amount || !data.email || !data.phone"
              [loading]="data.loading || data.busy"
              [style]="{width:'29vw', background:'#56006B','border-radius':'20px'}" (click)="confirmElect()"></p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-auto">
      <img src="../../assets/skins/hand.png" alt="">
    </div>
  </div>
  <div *ngIf="data.loading">
    <div class="center-flex-n">
      <div class="loading"></div>
    </div>
  </div>